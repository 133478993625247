import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Stack, Typography } from "@mui/material";
import { Button, TextInput, Logo, CarWrapper } from "../../../components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import styles from "./ForgotScreen.module.scss";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../api/services/authService";
// import toast from "react-hot-toast";
import Toaster from "../../../components/Toaster";
import { emailValidation } from "../../../utils/validation";

const validationSchema = Yup.object().shape({
    email: emailValidation,
});

const Forgot = () => {
    const navigate = useNavigate();

    // const handleForgot = async (values) => {
    //     console.log("forgot Screen values:", values);

    //     try {
    //         const formData = {
    //             email: values.email
    //         }

    //         const response = await forgotPassword(formData);
    //         console.log("success", response);
    //         toast.success(response?.message);
    //         navigate("/confirmpassword", { state: { email: values.email } });
    //     }
    //     catch (e) {
    //         const { message } = e.response.data;
    //         console.log(e)
    //         toast.error(message)
    //     }
    // };

    const handleForgot = async (values) => {
        console.log("forgot Screen values:", values);

        const formData = {
            email: values.email
        }

        await Toaster.handleApiCall(() => forgotPassword(formData),
            "An email has been sent",
            (response) => {
                navigate('/confirmpassword', { state: { email: values.email } });
                // navigate("/profile", { state: { name: response.data.name } });
            }
        );
    };


    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Stack>
                    <Logo width={150} />
                </Stack>

                <Grid container spacing={2}>
                    <Grid
                        size={{ xs: 12, md: 7 }}
                        sx={{ margin: { xs: "0 36px", md: "0" } }}
                    >
                        <Stack spacing={2} justifyContent="center" alignItems="center">
                            <Stack
                                spacing={2}
                                sx={{ width: { xs: "100%", md: "78%" } }}
                                className={styles.Container}
                            >
                                <Stack>
                                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                                        Forgot Password
                                    </Typography>
                                    <Typography
                                        variant="body4"
                                        sx={{
                                            textAlign: "center",
                                            marginBottom: "30px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            marginTop: "4px",
                                        }}
                                    >
                                        Please enter your email to reset the password
                                    </Typography>

                                    <Formik
                                        initialValues={{ email: "" }}
                                        validationSchema={validationSchema}
                                        // onSubmit={(values) => {
                                        //     console.log('forgot Screen values:', values);
                                        //     navigate('/confirmpassword', { state: { email: values.email } });
                                        // }}
                                        onSubmit={handleForgot}
                                        validateOnMount={true}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            values,
                                            errors,
                                            touched,
                                            isValid,
                                        }) => (
                                            <form onSubmit={handleSubmit} autoComplete='off' autoCapitalize="off">
                                                <Stack spacing={2}>
                                                    <Typography variant="p">Email Address</Typography>
                                                    <TextInput
                                                        name="email"
                                                        // type="email"
                                                        value={values.email}
                                                        onChange={handleChange("email")}
                                                        onBlur={handleBlur}
                                                        // label="Email address"
                                                        label="Enter your email address"
                                                        error={values.email && Boolean(errors.email)}
                                                        helperText={values.email && errors.email}
                                                    />

                                                    {/* <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={!isValid}
                                                        sx={{
                                                            backgroundColor: !isValid ? '#22B14B59' : '#22B14B',
                                                            color: !isValid ? '#ffffff' : '#fff',
                                                            '&:hover': {
                                                                backgroundColor: !isValid ? '#22B14B59' : '#1e9e3b',
                                                            },
                                                            '&.Mui-disabled': {
                                                                backgroundColor: '#22B14B59',
                                                                color: '#ffffff',
                                                            },
                                                            marginTop: '40px !important',
                                                            textTransform: "capitalize"
                                                        }}
                                                    >
                                                        Reset Password
                                                    </Button> */}
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={!isValid}
                                                        sx={{
                                                            marginTop: "40px !important",
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        Reset Password
                                                    </Button>
                                                </Stack>
                                            </form>
                                        )}
                                    </Formik>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid
                        size={{ xs: 12, md: 5 }}
                        sx={{ marginLeft: { xs: "36px", md: "auto" } }}
                    >
                        <CarWrapper />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Forgot;
