import * as Yup from 'yup';
import {
  reservedBidValidation, modelValidation, modelYearValidation,
  makeValidation, transmissionValidation, engineCapacityValidation,
  engineTypeValidation, bodyTypeValidation, mileageValidation,
  locationValidation, seatCapacityValidation, exteriorColorValidation,
  interiorColorValidation, filesValidation, featuresValidation,
  startingBidValidation, loanOrLeaseStatusValidation, companyNameValidation,
  dueAmountValidation, keyFobsValidation, exteriorDamagesStatusValidation,
  interiorDamagesStatusValidation, mechanicalIssuesStatusValidation,
  modificationStatusValidation, tireConditionValidation, conditionRatingValidation,
  accidentHistoryValidation, exteriorDamagesValidation, interiorDamagesValidation,
  mechanicalIssuesValidation, modificationValidation
} from "../../../utils/validation";

const validationSchema = Yup.object().shape({
  reservedBid: reservedBidValidation, 
  modelYear: modelYearValidation, 
  make: makeValidation,
  model: modelValidation, 
  transmission: transmissionValidation, 
  engineType: engineTypeValidation,
  bodyType: bodyTypeValidation, 
  mileage: mileageValidation, 
  location: locationValidation,
  engineCapacity: engineCapacityValidation, 
  seatCapacity: seatCapacityValidation, 
  exteriorColor: exteriorColorValidation,
  interiorColor: interiorColorValidation, 
  features: featuresValidation, 
  files: filesValidation,
  driversLicense: filesValidation, 
  vehicleRegistration: filesValidation, 
  startingBid: startingBidValidation,
  loanOrLeaseStatus: loanOrLeaseStatusValidation, 
  exteriorDamagesStatus: exteriorDamagesStatusValidation,
  interiorDamagesStatus: interiorDamagesStatusValidation, 
  mechanicalIssuesStatus: mechanicalIssuesStatusValidation,
  modificationStatus: modificationStatusValidation, 
  tireCondition: tireConditionValidation,
  conditionRating: conditionRatingValidation, 
  companyName: companyNameValidation, 
  dueAmount: dueAmountValidation,
  keyFobs: keyFobsValidation, 
  accidentHistory: accidentHistoryValidation, 
  exteriorDamage: exteriorDamagesValidation,
  interiorDamage: interiorDamagesValidation, 
  mechanicalIssues: mechanicalIssuesValidation, 
  modification: modificationValidation,
});

export default validationSchema;
