// import React from 'react'
// import { Box, Stack, Typography, } from '@mui/material';
// import { Button } from '../../../components';
// import { Icon } from "@iconify/react";
// import Grid from '@mui/material/Grid2';
// import Car from '../../../assets/images/Car_jeep.png'

// const HomePage = () => {

//     return (
//         <>
//             <Box sx={{ flexGrow: 1 }}>
//                 <Grid container spacing={0} alignItems="center" >
//                     <Grid size={{ xs: 12, md: 6 }} sx={{ padding: {xs: '0 0 0 10px', md:'0 0 0 100px'} }}>
//                         <Stack spacing={2} justifyContent="center" alignItems="left">
//                             <Typography variant="h2">
//                                 Find, Buy and <br /> Sell a car <span style={{ color: '#21B14B' }}>Easily</span>
//                             </Typography>
//                             <Typography variant="body1" sx={{ color: '#272727' }}>
//                                 Get a car wherever and whenever you <br /> need it with your iOS and Android device.
//                             </Typography>


//                             <Grid size={{ xs: 12, md: 12 }} gap={'10px'} display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} width={'fit-content'}>
//                                 <Button color='secondary' sx={{height:'auto'}}>
//                                     <Icon icon="ri:apple-fill" style={{ fontSize: '45px', marginRight: '10px', color: 'white' }} />
//                                     <Stack sx={{ textAlign: 'left' }}>
//                                         <Typography variant="p8" fontWeight={'500'}>Download on the</Typography>
//                                         <Typography fontWeight="bold" color="white" fontSize={"16px"}>App Store</Typography>
//                                     </Stack>
//                                 </Button>

//                                 <Button color='secondary' sx={{height:'auto'}}>
//                                     <Icon icon="logos:google-play-icon" style={{ fontSize: '40px', marginRight: '10px' }} />
//                                     <Stack sx={{ lineHeight: '26px', textAlign: 'left' }}>
//                                         <Typography variant="p8" fontWeight={'500'}>Get it on the</Typography>
//                                         <Typography fontWeight="bold" color="white" fontSize={"16px"}>Google Play</Typography>
//                                     </Stack>
//                                 </Button>
//                             </Grid>
//                         </Stack>
//                     </Grid>


//                     <Grid size={{ xs: 12, md: 6 }} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
//                         <Box
//                             component="img"
//                             src={Car}
//                             alt="Car"
//                             sx={{
//                                 maxWidth: '100%',
//                                 height: 'auto',
//                                 objectFit: 'contain',
//                             }}
//                         />
//                     </Grid>
//                 </Grid>
//             </Box>
//         </>
//     );
// };

// export default HomePage



import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import { Button } from '../../../components';
import { Icon } from "@iconify/react";
import Grid from '@mui/material/Grid2';
import Car from '../../../assets/images/Car_jeep.png';
import { fetchAd } from "../../../api/services/consumerService";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader"
import { useAuth } from '../../../context/AuthContext';

const HomePage = () => {
    const [adData, setAdData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleFetchAd = async () => {
        try {
            const response = await fetchAd();
            const data = response?.data || [];
            console.log("fetchAd api response original", data);
            const currentTime = new Date().toISOString();

            // Update the status of ads based on endTime
            const updatedData = data.map((ad) => {
                if (ad.endTime && ad.endTime < currentTime && ad.status === "active") {
                    return { ...ad, status: "expired" };
                }
                return ad;
            });

            console.log("fetchAd api response modified", updatedData);
            setAdData(updatedData);
        } catch (error) {
            console.error("Error fetching ads:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user?.roleName === "consumer") {
            handleFetchAd();
        }
    }, []);


    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                {/* First Section */}
                <Grid container spacing={0} alignItems="center">
                    <Grid size={{ xs: 12, md: 6 }} sx={{ padding: { xs: '0 0 0 10px', md: '0 0 0 100px' } }}>
                        <Stack spacing={2} justifyContent="center" alignItems="left">
                            <Typography variant="h2">
                                Find, Buy and <br /> Sell a car <span style={{ color: '#21B14B' }}>Easily</span>
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#272727' }}>
                                Get a car wherever and whenever you <br /> need it with your iOS and Android device.
                            </Typography>
                            <Grid
                                size={{ xs: 12, md: 12 }}
                                gap={'10px'}
                                display={'flex'}
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                width={'fit-content'}
                            >
                                <Button color="secondary" sx={{ height: 'auto' }}>
                                    <Icon icon="ri:apple-fill" style={{ fontSize: '45px', marginRight: '10px', color: 'white' }} />
                                    <Stack sx={{ textAlign: 'left' }}>
                                        <Typography variant="p8" fontWeight={'500'}>
                                            Download on the
                                        </Typography>
                                        <Typography fontWeight="bold" color="white" fontSize={"16px"}>
                                            App Store
                                        </Typography>
                                    </Stack>
                                </Button>
                                <Button color="secondary" sx={{ height: 'auto' }}>
                                    <Icon icon="logos:google-play-icon" style={{ fontSize: '40px', marginRight: '10px' }} />
                                    <Stack sx={{ lineHeight: '26px', textAlign: 'left' }}>
                                        <Typography variant="p8" fontWeight={'500'}>
                                            Get it on the
                                        </Typography>
                                        <Typography fontWeight="bold" color="white" fontSize={"16px"}>
                                            Google Play
                                        </Typography>
                                    </Stack>
                                </Button>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                        sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Box
                            component="img"
                            src={Car}
                            alt="Car"
                            sx={{
                                maxWidth: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Auction Section */}
                {/* <Box sx={{ backgroundColor: "#074519", padding: "40px 20px" }}> */}
                <Box sx={{ backgroundImage: "linear-gradient(to right, #69CF87 1%, #074519 100%)", padding: "40px 20px" }}>
                    <Typography variant="body2" textAlign="center"  color="#FFFFFF" fontSize={35} fontWeight={'bold'} mb={4}>
                        ADVERTISEMENTS
                    </Typography>
                    {loading ? (
                        <Stack justifyContent="center" alignItems="center" sx={{ minHeight: "200px" }}>
                            <Loader />
                        </Stack>
                    ) : adData.length > 0 ? (
                        <Grid container spacing={4} justifyContent="center">
                            {adData.slice(0, 4).map((data) => (
                                <Grid xs={12} sm={6} md={3} key={data.id}>
                                    <Box
                                        width={285}
                                        height={400}
                                        padding={2}
                                        borderRadius={'12px'}
                                        textAlign={"center"}
                                        overflow={"hidden"}
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"space-between"}
                                        sx={{ backgroundColor: "white" }}
                                        boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
                                    >
                                        <Box
                                            component="img"
                                            src={data?.images?.[0]}
                                            alt={data?.makes?.name}
                                            sx={{
                                                width: "100%",
                                                height: "180px",
                                                objectFit: "contain",
                                                marginBottom: "12px",
                                            }}
                                        />
                                        {/* Car Details */}
                                        <Typography variant="p1" fontWeight="bold" >
                                            {data.makes?.name} {data.models?.name}
                                        </Typography>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body4" fontSize={13} display="flex" alignItems="center">
                                                <Icon icon="mdi:gas-station" fontSize={18} style={{ marginRight: 4 }} /> {data?.engine_types?.name}
                                            </Typography>
                                            <Typography variant="body4" fontSize={13} display="flex" alignItems="center">
                                                <Icon icon="material-symbols:auto-transmission-sharp" fontSize={18} style={{ marginRight: 4 }} /> {data?.transmission_types?.name || "Auto"}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body4" fontSize={13} display="flex" alignItems="center">
                                                <Icon icon="fa-solid:road" fontSize={16} style={{ marginRight: 4 }} /> {data?.vehicle_details?.mileage} CC
                                            </Typography>
                                            <Typography variant="body4" fontSize={13} display="flex" alignItems="center">
                                                <Icon icon="bxs:user" fontSize={16} style={{ marginRight: 4 }} />{" "}
                                                {data?.vehicle_details?.seatCapacity} Passengers
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Button
                                                variant="contained"
                                                color='secondary'
                                                fullWidth
                                            >
                                                View Details
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography variant='h6' textAlign="center">
                            There are no cars available in the listings.
                        </Typography>
                    )}
                </Box>

            </Box>
        </>
    );
};

export default HomePage;



