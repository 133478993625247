import { io } from "socket.io-client";

// const SOCKET_URL = "http://localhost:5000/";
const SOCKET_URL = "wss://getabid.com";

const socket = io(SOCKET_URL, {
  transports: ["websocket"], 
  reconnectionAttempts: 5,  
});

export default socket;
