import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const uploadFile = async (url) => {
    try {
      const endpoint = API_ENDPOINTS.UPLOADS.UPLOAD_IMAGES;
      const response = await axiosInstance.get(endpoint, {
        params: {
          url,
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };