import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";


const MechanicalConditionSection = ({ values, handleChange, errors, setFieldValue, makeOptions, modelOptions, transmissionOptions, engineTypeOptions, bodyTypeOptions, modelYearOptions, Options, fetchModel, disabled = false }) =>
(
    <>
        <Typography variant="p7" color="primary.main" fontWeight={600}>Mechanical Condition</Typography>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Engine*</Typography>
                <RadioInput
                    name="mechanicalCondition.engine"
                    value={values.mechanicalCondition?.engine}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.mechanicalCondition?.engine && !values?.mechanicalCondition?.engine}
                    helperText={errors?.mechanicalCondition?.engine}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Tranmission*</Typography>
                <RadioInput
                    name="mechanicalCondition.transmission"
                    value={values.mechanicalCondition?.transmission}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.mechanicalCondition?.transmission && !values?.mechanicalCondition?.transmission}
                    helperText={errors?.mechanicalCondition?.transmission}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Brakes*</Typography>
                <RadioInput
                    name="mechanicalCondition.brakes"
                    value={values.mechanicalCondition?.brakes}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.mechanicalCondition?.brakes && !values?.mechanicalCondition?.brakes}
                    helperText={errors?.mechanicalCondition?.brakes}
                    disabled={disabled}
                />
            </Stack>
        </Grid>


        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Steering*</Typography>
                <RadioInput
                    name="mechanicalCondition.steering"
                    value={values.mechanicalCondition?.steering}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.mechanicalCondition?.steering && !values?.mechanicalCondition?.steering}
                    helperText={errors?.mechanicalCondition?.steering}
                    disabled={disabled}
                />
            </Stack>
        </Grid>


        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Suspension*</Typography>
                <RadioInput
                    name="mechanicalCondition.suspension"
                    value={values.mechanicalCondition?.suspension}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.mechanicalCondition?.suspension && !values?.mechanicalCondition?.suspension}
                    helperText={errors?.mechanicalCondition?.suspension}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Exhaust System*</Typography>
                <RadioInput
                    name="mechanicalCondition.exhaustSystem"
                    value={values.mechanicalCondition?.exhaustSystem}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.mechanicalCondition?.exhaustSystem && !values?.mechanicalCondition?.exhaustSystem}
                    helperText={errors?.mechanicalCondition?.exhaustSystem}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Battery*</Typography>
                <RadioInput
                    name="mechanicalCondition.battery"
                    value={values.mechanicalCondition?.battery}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.mechanicalCondition?.battery && !values?.mechanicalCondition?.battery}
                    helperText={errors?.mechanicalCondition?.battery}
                    disabled={disabled}
                />
            </Stack>
        </Grid>
    </>
)
export default MechanicalConditionSection