import React from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { Logo, CarWrapper } from '../../../components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import styles from './ConfirmScreen.module.scss';
// import toast from "react-hot-toast";
import { forgotPassword } from "../../../api/services/authService";
import { useLocation } from 'react-router-dom';
import Toaster from '../../../components/Toaster';

const Confirm = () => {
    const location = useLocation();
    const email = location.state?.email || '[your email address]';

    // const handleConfirm = async () => {
    //     try {
    //         const formData = {
    //             email: email
    //         }

    //         const response = await forgotPassword(formData);
    //         console.log("success", response);
    //         toast.success(response?.message);
    //     }
    //     catch (e) {
    //         const { message } = e.response.data;
    //         console.log(e)
    //         toast.error(message)
    //     }
    // };

    const handleConfirm = async (values) => {

        const formData = {
            email: email
        }

        await Toaster.handleApiCall(() => forgotPassword(formData),
            "An email has been sent",
            (response) => { }
        );
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Stack>
                    <Logo width={150} />
                </Stack>


                <Grid container
                    spacing={2}
                >
                    <Grid size={{ xs: 12, md: 7 }} sx={{ margin: { xs: '0 36px', md: '0' } }}>
                        <Stack spacing={2} justifyContent="center" alignItems="center">
                            <Stack spacing={2} sx={{ width: { xs: '100%', md: '78%' } }} className={styles.Container}>
                                <Stack>
                                    <Typography variant="h4" sx={{ textAlign: 'center' }}>Check your email</Typography>
                                    <Typography variant="body4" sx={{ textAlign: 'center', marginBottom: '30px', fontSize: '18px', marginTop: '30px' }}>A password reset email has been sent to <span style={{ fontWeight: 700, color: '#262626' }}>{email}</span>. Follow the link in the email to create a new password.</Typography>
                                    <Typography variant="body4" sx={{ textAlign: 'center', marginBottom: '46px', fontSize: '20px', fontWeight: '700', marginTop: '4px' }}>Haven’t got the email yet? <Link onClick={handleConfirm} sx={{ fontWeight: 700, cursor: "pointer" }}>Resend email</Link></Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>


                    <Grid size={{ xs: 12, md: 5 }} sx={{ marginLeft: { xs: '36px', md: 'auto' } }}>
                        <CarWrapper />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Confirm;