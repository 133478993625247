import React, { useState, useEffect } from "react";
import { Stack, Box, Button, Card as MuiCard, CardContent, Typography, Divider, ListItemText, ListItem, List } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { TimerProvider, useTimer } from "../../../context/TimerContext"; 
import { AuctionTimer, TimerProviderWrapper } from "../../../components/Timer"
import { TextInput, Modal, Toaster } from "../../../components";
import { startNegotiation, acceptNegotiation, counterNegotiation, } from "../../../api/services/negotiationService";
import { useAuth } from "../../../context/AuthContext";
import { acceptBid } from "../../../api/services/consumerService";
import { useNavigate } from "react-router-dom";
import socket from "../../../config/socket";

const BidTrackerCard = ({ data, refreshData }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const listingId = data?.id;
    const { endTime } = data || {};
    const negotiation_details = data?.negotiation_details?.[data.negotiation_details.length - 1]
    const latest_negotiation_id = data?.negotiation_details?.[data.negotiation_details.length - 1]?.id;
    const highestBidder = data?.dealer_bids[0]
    const [modalOpen, setModalOpen] = useState(false);
    const [price, setPrice] = useState('')
    const currentTime = new Date().toISOString();
    const hasNegotiatingDetails = data?.negotiation_details?.length > 0;
    const [dealerBids, setDealerBids] = useState(data?.dealer_bids || []);
    const [highestBid, setHighestBid] = useState(
        Math.max(...(data?.dealer_bids?.map((bid) => bid.amount) || [0]))
    );
    const consumerId = data?.user_id;
    const dealerId = highestBidder?.dealer_id;
    const [negotiationUpdates, setNegotiationUpdates] = useState(data?.negotiation_details || []);

    const isValidPrice = !isNaN(price) && Number(price) > highestBidder?.amount && Number(price) > 0;
    const isValidNegotiationPrice = !isNaN(price) && Number(price) > negotiation_details?.offerAmount && Number(price) > 0;

    const handleStartNegotiation = async () => {
        console.log()
        try {
            const response = await Toaster.handleApiCall(
                () => startNegotiation(data.id, highestBidder?.id, price, highestBidder?.dealer_id),
                "Negotiation started",
                () => {
                }
            );
        } catch (error) {
            console.error("Error Negotiation:", error);
        }
        refreshData();
        // setModalOpen(false);
        setPrice('');
    }

    const handleCounterNegotiation = async () => {
        try {
            const response = await Toaster.handleApiCall(
                () => counterNegotiation(data?.id, latest_negotiation_id, price),
                "Counter Negotiation",
                () => {
                }
            );
        } catch (error) {
            console.error("Error Negotiation:", error);
        }
        refreshData();
        // setModalOpen(false);
        setPrice('');
    }

    const handleAcceptNegotiation = async () => {
        try {
            const response = await Toaster.handleApiCall(
                () => acceptNegotiation(data.id, latest_negotiation_id),
                "Negotiation Offer Accepted",
                () => {
                }
            );
        } catch (error) {
            console.error("Error accepting Negotiation:", error);
        }
        refreshData();
        // setModalOpen(false);
        setPrice('');
    }

    const handleAcceptBid = async () => {
        try {
            const response = await Toaster.handleApiCall(
                () => acceptBid(highestBidder.listing_id, highestBidder.id),
                "Bid Accepted",
                () => {
                }
            );
        } catch (error) {
            console.error("Error accepting Negotiation:", error);
        }
        refreshData();
    }

    const handleInspectionForm = () => {
        navigate(`/ads/inspection/${data.id}`)
    }

    useEffect(() => {
        if (listingId) {
            // Join the listing room for WebSocket
            socket.emit("join_listing", { listingId });

            // Listen for new bid events
            socket.on("new_bid", (data) => {
                console.log("New bid received:", data);

                if (data.listing_id == listingId) {
                    setDealerBids((prevBids) => [data, ...prevBids]);
                    setHighestBid((prevHighest) =>
                        Math.max(prevHighest, data.amount)
                    );
                }
            });
        }

        // Cleanup WebSocket listener on unmount
        return () => {
            socket.off("new_bid");
        };
    }, [listingId]);

    useEffect(() => {
        setDealerBids(data?.dealer_bids)
        setHighestBid(data?.dealer_bids[0]?.amount)
        setNegotiationUpdates(data?.negotiation_details)
    }, [data])


    useEffect(() => {
        if (consumerId && dealerId) {
            socket.emit("join_negotiation", { consumerId, dealerId });
            console.log(consumerId, dealerId);

            socket.on("negotiation_update", (update) => {
                console.log("Negotiation update received:", update);

                if (update?.dealerId === dealerId) {
                    setNegotiationUpdates((prevUpdates) => [update, ...prevUpdates]);
                    refreshData();
                }
                else {
                    refreshData();
                }

            });
        }

        return () => {
            socket.off("negotiation_update");
        };
    }, [listingId]);

    return (
        <TimerProviderWrapper endTime={data?.endTime}>
            <Box flex="1">
                {data?.status !== 'pending' && data?.status !== 'approved' && (
                    <Stack direction="row" spacing={1} alignItems="center" mb={2}>
                        <AccessTimeIcon color="success" fontSize="small" />
                        <Typography variant="body2">
                            <AuctionTimer />
                        </Typography>
                    </Stack>
                )}

                <Stack mb={1}>
                    <Typography variant="h8" fontWeight="bold">
                        {data?.vehicle_details?.modelYear}  {data?.makes?.name}
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body6">Reserved Price</Typography>
                    <Typography variant="h7">
                        USD {data?.reservedPrice}
                    </Typography>
                </Stack>

                <Divider sx={{ my: 4 }} />

                <MuiCard
                    sx={{
                        bgcolor: "third.secondary",
                        borderRadius: "10px",
                        boxShadow: "none",
                    }}
                >
                    <CardContent style={{ paddingBottom: "8px" }}>
                        <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
                            <Typography variant="h7">
                                Bid Tracker
                            </Typography>
                            <Typography variant="body8" fontSize={14}>
                                {dealerBids?.length || 0} Bids made
                            </Typography>
                        </Box>
                        <List disablePadding>
                            {dealerBids?.map((bid, index) => (
                                <ListItem
                                    key={index}
                                    disableGutters
                                    sx={{
                                        paddingTop: 0,
                                        paddingBottom: { xs: "40px", md: "15px" },
                                    }}
                                >
                                    <ListItemText
                                        primary={`$${bid?.amount}`}
                                        // variant='h7'
                                        primaryTypographyProps={{
                                            fontSize: "12px",
                                            fontFamily: "Domine",
                                            fontWeight: 700,
                                            color: "#262626",
                                        }}
                                    />

                                    {(endTime <= currentTime && index === 0) && (
                                        data?.reservedPrice <= highestBidder?.amount || (data?.status === "sold" && (!data?.negotiation_exists)) ? (
                                            <Button variant="containedAccepted">
                                                Offer Accepted
                                            </Button>
                                        ) : (
                                            <Box
                                                display="flex"
                                                flexDirection={{ xs: "column", sm: "row" }}
                                                gap={1}
                                            >
                                                {data?.negotiation_exists === true ? (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setModalOpen(true); // Same operation as 'Negotiate'
                                                        }}
                                                    >
                                                        View Negotiation
                                                    </Button>
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="outlinedSecondary"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={() => {
                                                                setModalOpen(true);
                                                            }}
                                                        >
                                                            Negotiate
                                                        </Button>
                                                        <Button
                                                            variant="filledSecondary"
                                                            color="success"
                                                            size="small"
                                                            onClick={handleAcceptBid}
                                                        >
                                                            Accept
                                                        </Button>
                                                    </>
                                                )}
                                            </Box>
                                        )
                                    )}

                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </MuiCard>
            </Box>

            {(!data?.closedAmount) ? (
                null
            ) : (
                <MuiCard
                    sx={{
                        bgcolor: "third.secondary",
                        borderRadius: "10px",
                        boxShadow: "none",
                        mt: "5%"
                    }}
                >
                    <CardContent style={{ paddingBottom: "8px" }}>
                        <Box display="flex" flexDirection="column" gap={2} marginBottom={1}>
                            <Typography variant="h7">
                                Accepted Offer Details
                            </Typography>

                            <Typography variant="body8" fontSize={14}>
                                Deal Confirmed on: <Typography variant="p4">${data?.closedAmount}</Typography>
                            </Typography>


                            {!data.inspectionCompleted && (
                                <Typography variant="body8" fontSize={14}>
                                    Our inspection team will visit you shortly to perform the inspection and complete the required report.
                                </Typography>
                            )}

                            {!data.inspectionCompleted ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    // disabled
                                    onClick={handleInspectionForm}
                                    sx={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: 'bold' }}
                                >
                                    Insception Form
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled
                                    // onClick={handleInspectionForm}
                                    sx={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: 'bold' }}
                                >
                                    Insception Done
                                </Button>
                            )}
                        </Box>
                    </CardContent>
                </MuiCard>
            )}


            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                    setPrice('')
                }
                }
                width={{ xs: '90%', sm: '50%', md: '40%', lg: '30%' }}
            // height="auto"
            >
                <Typography variant="h5">Add Your Offer</Typography>

                {hasNegotiatingDetails ? (
                    <Box display="flex" flexDirection="column" alignItems="center" mt={5} >
                        {data?.negotiation_details.map((negotiation, index) => (
                            <Box
                                key={index} display="flex" alignItems="center" position="relative" mb="30px" width={'100%'}
                            // sx={{ marginBottom: index < data.dealer_bids.length - 1 ? 2 : 0 }}
                            >
                                <Typography variant="p4" width={70}>
                                    {negotiation.initiator_id === user?.id ? "You" : "Dealer"}
                                </Typography>
                                < Box display="flex" flexDirection="column" alignItems="center" ml={1} mr={1}>
                                    <Box width="16px" height="16px" borderRadius="50%" bgcolor="primary.main" border="3px solid #DEDEDE" zIndex={1} />
                                    {index < data.negotiation_details.length - 1 && (
                                        <Box width="3px" height="60px" bgcolor="#DEDEDE" position="absolute" />
                                    )}
                                </Box>

                                <Typography variant="h9" mr={'5px'}>
                                    ${negotiation?.offerAmount}
                                    {negotiation.status !== 'ongoing' && (
                                        <Typography variant="p4" ml={'20px'} textTransform="capitalize" color={negotiation.status === 'accepted' ? 'primary.main' : 'error.main'}>
                                            ({negotiation.status})
                                        </Typography>
                                    )}
                                </Typography>

                                {(negotiation_details?.status === 'ongoing' &&
                                    ((index === 1 && data?.negotiation_details.length === 2))) && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleAcceptNegotiation}
                                            sx={{ ml: 'auto', width: '120px', fontSize: '14px' }}
                                        >
                                            Accept
                                        </Button>
                                    )}
                            </Box>

                        ))}
                    </Box >
                ) : (
                    <Typography variant="h7" textAlign="center" display="flex" justifyContent="center" paddingTop="50px" >
                        You Haven’t Made Any Negotiation Yet.
                    </Typography>
                )}

                {/* {data?.status === 'expired' && data?.negotiation_details?.length <= 2 && ( */}
                {data?.negotiation_details?.length <= 2 && !data?.closedAmount && (
                    <Stack spacing={1} mt={'10%'}>
                        <Typography variant="p1">Add your price (Usd)</Typography>
                        <TextInput
                            name={price}
                            value={price}
                            type="number"
                            label="Please Enter"
                            fullWidth
                            onChange={(e) => setPrice(e.target.value)}
                            helperText={
                                price && (data?.negotiation_details?.length < 1 ? !isValidPrice : !isValidNegotiationPrice) && (
                                    data?.negotiation_details?.length < 1
                                        ? `The price must be higher than $${highestBidder?.amount}.`
                                        : `The price must be higher than $${negotiation_details?.offerAmount}.`
                                )
                            }
                            error={price && (data?.negotiation_details?.length < 1 ? !isValidPrice : !isValidNegotiationPrice)}
                        />
                    </Stack>
                )}

                {/* {data?.status === 'expired' && data?.negotiation_details?.length <= 2 && ( */}
                {data?.negotiation_details?.length <= 2 && !data?.closedAmount && (
                    <Stack mt={3} flexDirection="row" justifyContent="flex-end" gap="20px">
                        {data?.negotiation_details?.length < 1 ? (
                            <Button
                                variant="contained"
                                color="success"
                                sx={{ width: '120px', height: 'fit-content', backgroundColor: "primary.main", textTransform: 'capitalize', }}
                                onClick={handleStartNegotiation}
                                disabled={!isValidPrice}
                            >
                                Negotiate
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="success"
                                sx={{ width: '120px', height: 'fit-content', textTransform: 'capitalize', backgroundColor: "primary.main" }}
                                onClick={handleCounterNegotiation}
                                disabled={!isValidNegotiationPrice}
                            >
                                Negotiate
                            </Button>
                        )}
                    </Stack>
                )}
            </Modal>

        </TimerProviderWrapper>
    );
};

export default BidTrackerCard;
