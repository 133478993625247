import React, { useState } from 'react';
import { Drawer as MUIDrawer, Box } from '@mui/material';

const Drawer = ({ anchor = "left", width = 250, onClose, children, button }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }
        setIsOpen(open);

        if (!open && onClose) {
            onClose();
        }
    };

    return (
        <>
            <Box onClick={toggleDrawer(true)}>
                {button}
            </Box>

            <MUIDrawer
                anchor={anchor}
                open={isOpen}
                onClose={toggleDrawer(false)}
            >
                <Box sx={{ width }} role="presentation">
                    {children}
                </Box>
            </MUIDrawer>
        </>
    );
};

export default Drawer;
