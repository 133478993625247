import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoader } from '../../context/LoaderContext';

const Loader = () => {
  const { loading } = useLoader();

  if (!loading) return null;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 9999 }}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
