import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { PublicNavbar } from '../components';

const PublicLayout = ({ role }) => {
    const { isAuthenticated } = useAuth();
  const location = useLocation();

  // Render PublicNavbar only on the homepage
  const showPublicNavbar = location.pathname === '/' && !isAuthenticated;

    return (
        <>
           {showPublicNavbar && <PublicNavbar />}
            <Outlet />
        </>
    );
};

export default PublicLayout;
