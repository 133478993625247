import * as Yup from 'yup';
import {
  modelValidation, modelYearValidation,
  makeValidation, mileageValidation,
  inspectorNameValidation,
  // inspectorDateValidation,
  licensePlateValidation,
  exteriorConditionValidation,
  interiorConditionValidation,
  mechanicalConditionValidation,
  safetyFeaturesValidation,
  additionalChecksValidation,
  inspectorCommentsValidation,
} from "../../../utils/validation";

const validationSchema = Yup.object().shape({
  modelYear: modelYearValidation,
  make: makeValidation,
  model: modelValidation,
  mileage: mileageValidation,
  inspectorName: inspectorNameValidation,
  // inspectorDate: inspectorDateValidation,
  exteriorCondition: exteriorConditionValidation,
  interiorCondition: interiorConditionValidation,
  mechanicalCondition: mechanicalConditionValidation,
  safetyFeatures: safetyFeaturesValidation,
  additionalChecks: additionalChecksValidation,
  inspectorComments: inspectorCommentsValidation,
});

export default validationSchema;
