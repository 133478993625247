import React from 'react';
import { Autocomplete, TextField, FormControl, FormHelperText } from '@mui/material';
import styles from './SelectInput.module.scss';

const SelectInput = ({
  name, label, value, touched, setFieldValue, onChange, options, error, helperText, optionLabelKey = 'label', optionValueKey = 'value', placeholder = 'Select an option', textFieldProps = {}, autocompleteProps = {}, ...props
}) => {
  return (
    <FormControl fullWidth variant="outlined" className={styles.formControl} error={Boolean(error)}>
      <Autocomplete
        id={name}
        options={options}
        getOptionLabel={(option) => option[optionLabelKey] || ''}
        value={options.find((option) => option[optionValueKey] === value) || null}
        onChange={(event, newValue) => {
          // setFieldValue(name, newValue || null);
          onChange({
            target: {
              name,
              value: newValue ? newValue[optionValueKey] : '',
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={!value ? label : ''}
            placeholder={label}
            autoComplete='off'
            variant="outlined"
            error={Boolean(error)}
            helperText={helperText}
            {...textFieldProps}
          />
        )}
        {...autocompleteProps}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;



// import React from 'react';
// import { Autocomplete, TextField, FormControl, FormHelperText } from '@mui/material';
// import styles from './SelectInput.module.scss';

// const SelectInput = ({
//   name, label, value, setFieldValue, options, error, helperText, optionLabelKey = 'label', optionValueKey = 'value', placeholder = 'Select an option', textFieldProps = {}, autocompleteProps = {}, ...props
// }) => {
//   return (
//     <FormControl fullWidth variant="outlined" className={styles.formControl} error={Boolean(error)}>
//       <Autocomplete
//         id={name}
//         options={options}
//         getOptionLabel={(option) => option[optionLabelKey] || ''}
//         value={options.find((option) => option[optionValueKey] === value[optionValueKey]) || ''}
//         onChange={(event, newValue) => {
//           setFieldValue(name, newValue || '');
//         }}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             label={!value ? label : ''}
//             placeholder={placeholder}
//             variant="outlined"
//             error={Boolean(error)}
//             helperText={helperText}
//             {...textFieldProps}
//           />
//         )}
//         {...autocompleteProps}
//         {...props}
//       />
//       {/* {helperText && <FormHelperText>{helperText}</FormHelperText>} */}
//     </FormControl>
//   );
// };

// export default SelectInput;
