import React, { useState, useEffect } from "react";
import { Button, Container, Typography, Stack, Box } from "@mui/material";
import AdViewCard from "../../../components/AdViewCard";
import { fetchAd } from "../../../api/services/consumerService";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader"

const MyAd = () => {
  const [adData, setAdData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // const handleFetchAd = async () => {
  //   try {
  //     const response = await fetchAd();
  //     // setAdData(response);
  //     const data = response?.data || [];
  //     console.log("fetchAd api response", data);
  //     setAdData(data);
  //   } catch (error) {
  //     console.error("Error fetching ads:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  ///this to be changed
  const handleFetchAd = async () => {
    try {
      const response = await fetchAd();
      const data = response?.data || [];
      console.log("fetchAd api response original", data);
      const currentTime = new Date().toISOString();

      // Update the status of ads based on endTime
      const updatedData = data.map((ad) => {
        if (ad.endTime && ad.endTime < currentTime && ad.status === "active") {
          return { ...ad, status: "expired" };
        }
        return ad;
      });

      console.log("fetchAd api response modified", updatedData);
      setAdData(updatedData);
    } catch (error) {
      console.error("Error fetching ads:", error);
    } finally {
      setLoading(false);
    }
  };


  const handlePlaceAd = () => {
    navigate("/ads/create");
  };

  const menuItemsByStatus = {
    active: [
      { label: "Edit Reserved Price", action: "editPrice" },
      // { label: "Remove", action: "remove" },
    ],
    live: [
      { label: "Edit Reserved Price", action: "editPrice" },
      // { label: "Remove", action: "remove" },
    ],
    approved: [
      { label: "Edit", action: "edit" },
      // { label: "Remove", action: "remove" },
      { label: "Go Live", action: "live" },
    ],
    pending: [
      { label: "Edit", action: "edit" },
      // { label: "Remove", action: "remove" },
    ],
    rejected: [
      { label: "Edit", action: "edit" },
      // { label: "Remove", action: "remove" },
    ],
    expired: [
      // { label: "Edit", action: "edit" },
      { label: "Repost", action: "repost" },
      // { label: "Remove", action: "remove" },
    ],
  };

  const getMenuItems = (status) => menuItemsByStatus[status] || [];

  useEffect(() => {
    handleFetchAd();
  }, []);

  return (
    <>
      <Container sx={{ py: 4, alignItems: 'start' }}>
        <Box
          bgcolor={"#fff"}
          borderRadius={"12px"}
          boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.1)"}
          padding={{ xs: 2, md: 4 }}
          width={"100%"}
        >
          {loading ? (
            <Loader />
          ) : adData && adData.length > 0 ? (
            <Stack spacing={2}>
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
                  My Ads
                </Typography>
              </Stack>
              {adData?.map((data) => (
                <AdViewCard
                  key={data?.id}
                  userId={data?.user_id}
                  route={`/ads/${data.id}`}
                  listingId={data?.id}
                  image={data?.images[0]}
                  modelYear={data?.vehicle_details?.modelYear}
                  title={data?.makes.name}
                  subtitle="Reserved Price"
                  price={`USD ${data?.reservedPrice}`}
                  details={[
                    { key: "engine", icon: "mdi:engine", value: data?.vehicle_details?.engineCapacity },
                    { key: "mileage", icon: "fa-solid:road", value: data?.vehicle_details?.mileage },
                    { key: "fuel", icon: "mdi:gas-station", value: data?.engine_types?.name },
                    { key: "drive", icon: "material-symbols:auto-transmission-sharp", value: data?.transmission_types?.name },
                  ]}
                  time={data?.auctionDuration}
                  status={data?.status}
                  menuItems={getMenuItems(data?.status)}
                  handleFetchAd={handleFetchAd}
                  endTime={data?.endTime}
                />
              ))}
            </Stack>
          ) : (
            <Stack
              alignItems="center"
              spacing={2}
              sx={{ textAlign: "center", py: 4 }}
            >
              <Typography variant="h6" color="textSecondary">
                You haven't placed any ads yet!
              </Typography>
              <Typography color="textSecondary">
                Start by placing your first ad to reach potential buyers.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePlaceAd}
                sx={{ mt: 2 }}
              >
                Place Your Ad
              </Button>
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

export default MyAd;
