import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

let activeRequests = 0;

// Function to start the loader
const startLoader = (showLoader) => {
  if (activeRequests === 0) {
    showLoader(); // Show loader
  }
  activeRequests++;
};

// Function to stop the loader
const stopLoader = (hideLoader) => {
  activeRequests--;
  if (activeRequests === 0) {
    hideLoader(); // Hide loader
  }
};

// Function to set up the loader
export const setupLoader = (showLoader, hideLoader) => {
  // Request Interceptor: Add token or headers
  axiosInstance.interceptors.request.use(
    (config) => {
      startLoader(showLoader); // Start loader on request
      return config;
    },
    (error) => {
      stopLoader(hideLoader); // Stop loader on error
      return Promise.reject(error);
    }
  );
  

  // Response Interceptor: Handle responses and errors
  axiosInstance.interceptors.response.use(
    (response) => {
      stopLoader(hideLoader); // Stop loader on successful response
      return response;
    },
    (error) => {
      stopLoader(hideLoader); // Stop loader on error
      // Central error handling (e.g., redirect to login if 401 or 403)
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        localStorage.removeItem('authToken');
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
};


  // Request Interceptor: Add token or headers
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  

  // Response Interceptor: Handle responses and errors
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Central error handling (e.g., redirect to login if 401 or 403)
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        localStorage.removeItem('authToken');
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

export default axiosInstance;
