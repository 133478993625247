import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Stack, Typography, Link } from '@mui/material';
import { Button, TextInput, Logo, CarWrapper } from '../../../components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import styles from './LoginScreen.module.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { login } from "../../../api/services/authService";
import Toaster from '../../../components/Toaster';
import { useNavigate } from 'react-router-dom';
import {
  emailValidation,
  passwordValidation,
} from "../../../utils/validation"
import { useAuth } from '../../../context/AuthContext';

const validationSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

const Login = () => {
  const navigate = useNavigate()
  const { signIn, isAuthenticated } = useAuth();

  const handleLogin = async (values) => {
    console.log('Login form values:', values);

    const formData = {
      email: values.email,
      password: values.password,
    };

    await Toaster.handleApiCall(() => login(formData),
      "Logged in successfully!",
      (response) => {
        signIn(response.token, response.data);
        navigate("/profile", { state: { name: response.data.name } });
      }
    );
  }

  // if (isAuthenticated) {
  //   return <Navigate to="/" replace />;
  // }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Stack >
          <Logo width={150} />
        </Stack>


        <Grid container
          spacing={2}
        >
          {/* <Grid size={{ xs: 12, md: 6 }} sx={{ marginTop: { xs: 'auto', md: '50px' }, margin: { xs: '0 36px', md: 'auto' } }} > */}
          <Grid size={{ xs: 12, md: 7 }} sx={{ margin: { xs: '0 36px', md: '0' } }}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Stack spacing={2} sx={{ width: { xs: '100%', md: '78%' } }} className={styles.loginContainer}>
                <Stack>
                  <Typography variant="h4" sx={{ textAlign: 'center' }}>Login</Typography>
                  <Typography variant="body2" sx={{ textAlign: 'center', marginBottom: '30px', fontSize: '16px', fontWeight: '600', marginTop: '4px' }}>Welcome to Get A Bid</Typography>

                  <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                    validateOnMount={true}
                  >
                    {({ handleChange, handleSubmit, handleBlur, values, errors, isValid }) => (
                      <form onSubmit={handleSubmit} autoCapitalize='off' autoComplete='off'>
                        <Stack spacing={2}>
                          <Stack spacing={0.5}>
                            <Typography variant="p">Email Address</Typography>
                            <TextInput
                              name="email"
                              // type="email"
                              value={values.email}
                              onChange={handleChange('email')}
                              onBlur={handleBlur}
                              label="Enter your email address"
                              error={values.email && Boolean(errors.email)}
                              helperText={values.email && errors.email}
                            />
                          </Stack>

                          <Stack spacing={0.5}>
                            <Typography variant="p">Password</Typography>
                            <TextInput
                              name="password"
                              type="password"
                              value={values.password}
                              onChange={handleChange('password')}
                              onBlur={handleBlur}
                              label="Enter your password"
                              error={values.password && Boolean(errors.password)}
                              helperText={values.password && errors.password}
                            />
                          </Stack>

                          <Link href="/forgotpassword" sx={{ textAlign: 'right', display: 'block', fontFamily: 'Nunito', color: '#262626', textDecoration: 'none', fontWeight: '500', marginTop: '3px !important' }}>
                            Forgot Password?
                          </Link>

                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={!isValid}
                            endIcon={<ArrowForwardIcon sx={{ marginLeft: '0px' }} />}
                            sx={{
                              marginTop: '40px !important',
                              // textTransform: "capitalize"
                            }}
                          >
                            Login
                          </Button>
                        </Stack>
                      </form>
                    )}
                  </Formik>

                  <Typography variant="body3" sx={{ textAlign: 'center', marginTop: '46px !important' }}>
                    Don’t have an account? <Link href="/signup" sx={{ fontWeight: 700 }}>Sign Up</Link>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>


          <Grid size={{ xs: 12, md: 5 }} sx={{ marginLeft: { xs: '36px', md: 'auto' } }}>
            <CarWrapper />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;