import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const fetchAllUsers = async (role) => {
    try {
        const endpoint = API_ENDPOINTS.ADMIN.GET_USERS;
        const response = await axiosInstance.get(endpoint, {
            params: {
                role,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateUserStatus = async (userId, status) => {
    try {
        const endpoint = API_ENDPOINTS.ADMIN.UPDATE_USER_STATUS.replace('userId', userId);
        const response = await axiosInstance.patch(endpoint, {
            status,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAllAds = async (status) => {
    try {
        // const response = await axiosInstance.get(API_ENDPOINTS.ADMIN.GET_ALL_LISTINGS);
        const endpoint = API_ENDPOINTS.ADMIN.GET_ALL_LISTINGS;
        const response = await axiosInstance.get(endpoint, {
            params: {
                status,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateAdStatus = async (listingId, status, reason = null) => {
    try {
        const endpoint = API_ENDPOINTS.ADMIN.UPDATE_LISTING_STATUS.replace('id', listingId);
        const body = { status };

        if (status !== 'approved' && reason) {
            body.reason = reason;
        }

        const response = await axiosInstance.patch(endpoint, body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const viewInspection = async (Id) => {
    try {
        const endpoint = API_ENDPOINTS.ADMIN.GET_INSPECTION_BY_ID.replace(':inspectionId', Id);
        const response = await axiosInstance.get(endpoint);
        // const response = await axiosInstance.get(API_ENDPOINTS.LISTING.);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateInspectionStatus = async (inspectionId, status, reason = null) => {
    try {
        const endpoint = API_ENDPOINTS.ADMIN.UPDATE_INSPECTION_STATUS.replace(':inspectionId', inspectionId);
        const body = { status };

        if (status !== 'approved' && reason) {
            body.reason = reason;
        }

        const response = await axiosInstance.patch(endpoint, body);
        return response.data;
    } catch (error) {
        throw error;
    }
};