import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Stack, Typography } from '@mui/material';
import { Button, TextInput, Logo, CarWrapper } from '../../../components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import styles from './ResetScreen.module.scss';
import { resetPassword } from "../../../api/services/authService";
// import toast from "react-hot-toast";
import { useNavigate, useParams } from 'react-router-dom';
import {
    passwordValidation,
    confirmPasswordValidation
} from "../../../utils/validation"
import Toaster from '../../../components/Toaster';

const validationSchema = Yup.object().shape({
    password: passwordValidation,
    confirmPassword: confirmPasswordValidation
});

const Reset = () => {
    const navigate = useNavigate();
    const { token } = useParams();

    // const handleResetPassword = async (values) => {

    //     try {
    //         const formData = {
    //             newPassword: values.confirmPassword,
    //             token,
    //         }

    //         const response = await resetPassword(formData);
    //         console.log("success", response);
    //         toast.success(response?.message);
    //         navigate('/login')
    //     }
    //     catch (e) {
    //         const { message } = e.response.data;
    //         console.log(e)
    //         toast.error(message)
    //     }
    // };

    const handleResetPassword = async (values) => {

        const formData = {
            newPassword: values.confirmPassword,
            token,
        }

        await Toaster.handleApiCall(() => resetPassword(formData),
            "Your password has been changed successfully",
            (response) => {
                navigate('/login')
            }
        );
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Stack>
                    <Logo width={150} />
                </Stack>


                <Grid container
                    spacing={2}
                >
                    <Grid size={{ xs: 12, md: 7 }} sx={{ margin: { xs: '0 36px', md: '0' } }}>
                        <Stack spacing={2} justifyContent="center" alignItems="center">
                            <Stack spacing={2} sx={{ width: { xs: '100%', md: '78%' } }} className={styles.loginContainer}>
                                <Stack>
                                    <Typography variant="h4" sx={{ textAlign: 'center' }}>Set a new password</Typography>
                                    <Typography variant="body4" sx={{ textAlign: 'center', marginBottom: '30px', fontSize: '18px', lineHeight: '20px', marginTop: '4px' }}>Your password has been successfully reset. Create a new password. Ensure it differs from previous ones for security.</Typography>

                                    <Formik
                                        initialValues={{ password: '', confirmPassword: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleResetPassword}
                                        validateOnMount={true}
                                    >
                                        {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isValid }) => (
                                            <form onSubmit={handleSubmit} autoComplete='off' autoCapitalize="off">
                                                <Stack spacing={2}>
                                                    <Typography variant="p">Password</Typography>
                                                    <TextInput
                                                        name="password"
                                                        type="password"
                                                        value={values.password}
                                                        onChange={handleChange('password')}
                                                        onBlur={handleBlur}
                                                        // label="Email address"
                                                        label="New password"
                                                        error={values.password && Boolean(errors.password)}
                                                        helperText={values.password && errors.password}
                                                    />

                                                    <Typography variant="p">Confirm Password</Typography>
                                                    <TextInput
                                                        name="password"
                                                        type="password"
                                                        value={values.confirmPassword}
                                                        onChange={handleChange('confirmPassword')}
                                                        onBlur={handleBlur}
                                                        // label="Password"
                                                        label="Confirm password"
                                                        error={values.confirmPassword && Boolean(errors.confirmPassword)}
                                                        helperText={values.confirmPassword && errors.confirmPassword}
                                                    />

                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={!isValid}
                                                        // href="/Login"
                                                        onClick={handleSubmit}
                                                        // endIcon={<ArrowForwardIcon sx={{ marginLeft: '0px' }} />}
                                                        sx={{
                                                            marginTop: '40px !important',
                                                            textTransform: "capitalize"
                                                        }}
                                                    >
                                                        Update Password
                                                    </Button>
                                                </Stack>
                                            </form>
                                        )}
                                    </Formik>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>


                    <Grid size={{ xs: 12, md: 5 }} sx={{ marginLeft: { xs: '36px', md: 'auto' } }}>
                        <CarWrapper />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Reset;
