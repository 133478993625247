import React from 'react';
import { Modal as MUIModal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ open, onClose, children, width = 'auto', height = 'auto' }) => {
    return (
        <MUIModal
            open={open}
            slotProps={{
                backdrop: {
                    onClick: (e) => e.stopPropagation(),
                },
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box position="absolute" top="50%" left="50%" bgcolor="background.paper" boxShadow={24}
                // padding={4} borderRadius={5} width={width} height={height} sx={{ transform: 'translate(-50%, -50%)', overflowY: 'auto', maxHeight: '90vh'}}
                padding={4}
                borderRadius={2}
                width={width}
                height={height}
                sx={{
                    transform: 'translate(-50%, -50%)',
                    overflowY: 'auto',
                    maxHeight: { xs: '80vh', md: '90vh' },
                }}
            >
                <IconButton
                    onClick={onClose} position="absolute"
                    sx={{
                        position: 'absolute',
                        top: 25,
                        right: 15,
                        color: '#262626',
                    }}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                {children}
            </Box>
        </MUIModal>
    );
};

export default Modal;
