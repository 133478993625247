import React, {useState} from 'react';
import { Typography, Box, Button } from '@mui/material';
import { TextInput, Modal } from '../../../../components';
import { useNavigate } from 'react-router-dom';

const VinModal = ({ open, onClose, onSubmit }) => {
  const [vinNumber, setVinNumber] = useState('');
  const navigate = useNavigate();

  const handleSubmit = () => {
    onSubmit(vinNumber);
    // navigate(`/ads/create/${vinNumber}`);
    navigate('/ads/create', { state: { vinNumber } });
    setVinNumber(''); 
  };

  const handleClose = () => {
    setVinNumber(''); 
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} width={{ xs: '90%', sm: '50%', md: '40%', lg: 'auto' }}>
      <Typography id="modal-title" variant="h6">
          Provide Vehicle VIN#
        </Typography>
        <Typography id="modal-description" variant="h7" component="label" sx={{ display: 'block', mt: 5, mb: 1 }}>
          VIN Number
        </Typography>
        <Box display={"flex"} alignItems="center" gap={1}
          sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' } }}>
          <TextInput
            name="vinNumber"
            variant="outlined"
            label="Please Enter VIN#"
            fullWidth
            
            value={vinNumber}
            onChange={(e) => setVinNumber(e.target.value)}
          />
          <Button
            type='submit'
            variant="contained"
            color="secondary"
            sx={{
              height: 54,
              width: { xs: '100%', lg: 160, fontSize: '16px' },
            }}
            onClick={handleSubmit}
            disabled={!vinNumber.trim()}
          >
            Submit
          </Button>
        </Box>
    </Modal>
  );
};

export default VinModal;
