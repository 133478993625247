import * as React from "react";
import VinModal from "./VinModal";
import navbarData from './data.json'
import { Button } from "../../index";
import { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../assets/logo/logo.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import profile_avatar from "../../../assets/logo/profile_avatar.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from "../../../context/notificationContext";
import { UserMenu, MobileMenu, SellNowMenu, NotificationMenu } from ".././Menu";
import { AppBar, Box, Toolbar, Badge, IconButton, ImageListItem, Drawer as MuiDrawer, Button as MuiButton } from "@mui/material";

const PrivateNavbar = ({ selectedSection, setSelectedSection }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [sellNowAnchorEl, setSellNowAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  // const [avatar, setAvatar] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const { notifications, unreadCount, loading, fetchAndMarkNotificationsAsRead, } = useNotification();
  const { notifications, setNotifications, unreadCount, loading, fetchNotifications, fetchAndMarkNotificationsAsRead, hasMore, currentPage, setCurrentPage, } = useNotification();

  // if (user?.roleName) {
  //   console.log(user?.roleName)
  // }

  useEffect(() => {
    setName(user?.name || '');
    setEmail(user?.email || '');
  }, [user]);

  const rolePagesMap = {
    dealer: navbarData.dealerPages,
    admin: navbarData.adminPages,
    consumer: navbarData.consumerPages,
  };

  const pages = rolePagesMap[user?.roleName] || []

  const handleNavigation = (pageName) => {
    const page = pages.find(page => page.name === pageName);
    if (page) {
      navigate(page.route);
    }
  };

  const menuItems = [
    { section: "profile", label: "My Profile", route: "/profile" },
    // { section: "stats", label: "Stats", route: "/profile" },
    { section: "bankDetail", label: "Bank Detail", route: "/profile" },
    // { section: "history", label: "History", route: "/profile" },
  ];

  const handleMenuOpen = (setter) => (event) => setter(event.currentTarget);
  const handleMenuClose = (setter) => () => setter(null);


  const handleMenuItemClick = (section) => {
    const selectedMenuItem = menuItems.find((item) => item.section === section);
    if (selectedMenuItem) {
      // setSelectedSection(section);
      handleMenuClose(setAnchorElUser)();
      navigate(selectedMenuItem.route);
    }
  };

  const sellNowOptions = [
    { label: "VIN Number", description: "Auto-fetch car details with your", id: 1 },
    { label: "Manual", description: "Add all your car details by yours", id: 2 }
  ];

  const handleSellNowOptionSelect = (id) => {
    if (id === 1) {
      setOpenModal(true);
    } else if (id === 2) {
      navigate('/ads/create');

    }
    handleMenuClose(setSellNowAnchorEl)();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalSubmit = () => {
    console.log("VIN Submitted");
    handleModalClose();
  };


  const toggleDrawer = (open) => async () => {
    setIsDrawerOpen(open);

    if (open) {
      setCurrentPage(1);
      await fetchNotifications(1, false);
      await fetchAndMarkNotificationsAsRead();
    }
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setNotifications([]);
    // setCurrentPage(1);
    // fetchNotifications(1, false);
  };

  const fetchMoreNotifications = async () => {
    const nextPage = currentPage + 1;

    await fetchNotifications(nextPage, true);
    setCurrentPage(nextPage);
  };


  const handleNotificationClick = (listingId) => {
    const roleNavigationPaths = {
      consumer: `/ads/${listingId}`,
      dealer: `/bids/${listingId}`,
      admin: `/admin/ads/${listingId}`,
    };

    if (listingId && user?.roleName) {
      const path = roleNavigationPaths[user.roleName];
      if (path) {
        navigate(path);
      }
    }

    setIsDrawerOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{
        flexGrow: 1,
        // boxShadow: { xs: 'inherit', md: "none" },
        backgroundColor: "#ffffff !important", paddingTop: { xs: '5px', md: '0px' }
      }}>
        <Toolbar sx={{ paddingLeft: '0px', paddingRight: '15px' }}>
          <IconButton
            size="large"
            color="inherit"
            onClick={handleMenuOpen(setMobileMoreAnchorEl)}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <ImageListItem sx={{ width: { xs: 0, sm: 'auto', maxWidth: 200 } }}>
            <img src={logo} alt="Get A Bid Logo" />
          </ImageListItem>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "end", gap: "30px" }}>
            {pages.map((page) => (
              <MuiButton
                key={page.name}
                onClick={() => handleNavigation(page.name)}
                variant="containedPage"
                sx={{
                  my: 2, display: "block",
                  color: location.pathname === page.route ? 'primary.main' : 'inherit',
                }}
              >
                {page.name}
              </MuiButton>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: "flex" } }}>
            <IconButton size="large" color="inherit" onClick={toggleDrawer(true)}>
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>



            <IconButton
              size="large"
              onClick={handleMenuOpen(setAnchorElUser)}
              color="inherit"
            >
              <img src={profile_avatar} alt="user profile logo" style={{ borderRadius: '50%' }} />
            </IconButton>
            {user?.roleName === 'consumer' && (
              <Button
                // sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: { xs: '12px', sm: '16px' }, height: { xs: 'auto', sm: 'auto' }, backgroundColor: { xs: "transparent", sm: '#22B14B' }, color: { xs: 'black', sm: '#FFFFFF' }, padding: { xs: '0px', sm: '12px' } }}
                // sx={{ textTransform: "capitalize", fontWeight: '700', fontSize:{ xs: '16px', sm: '16px' } , height:{ xs: 'fit-content', sm: 'fit-content' } }}
                sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: '16px', height: 'fit-content' }}
                onClick={handleMenuOpen(setSellNowAnchorEl)}
              >
                Sell Now<KeyboardArrowDownIcon />
              </Button>)}
          </Box>
        </Toolbar>
      </AppBar>

      {MobileMenu({ mobileMoreAnchorEl, handleMenuClose: handleMenuClose(setMobileMoreAnchorEl), handleNavigation, pages })}
      {UserMenu({ anchorElUser, handleMenuClose: handleMenuClose(setAnchorElUser), handleMenuItemClick, menuItems, selectedSection, name, email, avatar: user?.image, logout })}
      {SellNowMenu({ sellNowAnchorEl, handleMenuClose: handleMenuClose(setSellNowAnchorEl), handleSellNowOptionSelect, sellNowOptions })}
      <VinModal open={openModal} onClose={handleModalClose} onSubmit={handleModalSubmit} />
      <MuiDrawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <NotificationMenu
          notifications={notifications}
          setNotifications={setNotifications}
          loading={loading}
          handleNotificationClick={handleNotificationClick}
          fetchMore={fetchMoreNotifications}
          hasMore={hasMore}
        />
      </MuiDrawer>


      <Box sx={{ marginTop: '100px' }} />
    </Box>
  );
};

export default PrivateNavbar;
