import React, { useState, useEffect } from "react";
import {
  Grid2 as Grid, Container, Typography, Stack, Box, Tab, Tabs, InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import AdViewCard from "../../../components/AdViewCard";
import { fetchAllAds } from "../../../api/services/adminService";
import { useNavigate } from "react-router-dom";
import { Loader, TextInput } from "../../../components";
import { useAuth } from "../../../context/AuthContext";

const Ads = () => {
  const [adsData, setAdsData] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useAuth()
  const navigate = useNavigate();

  const handleFetchAd = async (status = "pending") => {
    try {
      const response = await fetchAllAds(status);
      console.log('admin fetchAllAds', response)
      const data = response?.data || [];
      setAdsData(data);
      setFilteredAds(data);
    } catch (error) {
      console.error("Error fetching ads:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter ads based on Make name
    const filtered = adsData.filter((ad) =>
      ad?.makes?.name?.toLowerCase().includes(query)
    );
    setFilteredAds(filtered);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchQuery(""); // Reset search query when switching tabs
    if (newValue === 0) {
      handleFetchAd("pending");
    } else if (newValue === 1) {
      handleFetchAd("sold");
    }
  };

  useEffect(() => {
    handleFetchAd();
  }, []);

  return (
    <>
      <Container sx={{ py: 4, alignItems: "start" }}>
        <Box
          bgcolor={"#fff"}
          borderRadius={"12px"}
          boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.1)"}
          padding={{ xs: 2, md: 4 }}
          width={"100%"}
        >
          <Grid container justifyContent="space-between" alignItems="center" gap={2} sx={{ mb: 2 }}>
            <Grid>
              <Typography variant="h4" component="h1" lineHeight={'24px'}>
                Ads Management
              </Typography>
            </Grid>

            <Grid>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                sx={{ border: "2px solid #22B14B", borderRadius: '10px', overflow: "hidden", minHeight: "auto" }}
              >
                <Tab label="Pending Ads" sx={{ fontSize: "16px", borderBottom: "0px !important", minHeight: "auto" }} />
                <Tab label="Sold Ads" sx={{ fontSize: "16px", borderBottom: "0px !important", minHeight: "auto" }} />
              </Tabs>
            </Grid>

            <Grid>
              <TextInput
                label="Search by Make name"
                variant="outlined"
                size="small"
                name={searchQuery}
                value={searchQuery}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {loading ? (
            <Loader />
          ) : filteredAds.length > 0 ? (
            <Stack spacing={2}>
              {filteredAds.map((data) => (
                <AdViewCard
                  key={data?.id}
                  userId={data?.user_id}
                  route={`/admin/ads/${data.id}`}
                  listingId={data?.id}
                  inspectionId={data?.inspection_details?.id}
                  image={data?.images[0]}
                  modelYear={data?.vehicle_details?.modelYear}
                  title={data?.makes?.name}
                  subtitle="Reserved Price"
                  price={`USD ${data?.reservedPrice}`}
                  details={[
                    { key: "engine", icon: "mdi:engine", value: data?.vehicle_details?.engineCapacity },
                    { key: "mileage", icon: "fa-solid:road", value: data?.vehicle_details?.mileage },
                    { key: "fuel", icon: "mdi:gas-station", value: data?.engine_types?.name },
                    { key: "drive", icon: "material-symbols:auto-transmission-sharp", value: data?.transmission_types?.name },
                  ]}
                  time={data?.auctionDuration}
                  status={data?.status}
                  userRoleName={user.roleName}
                  inspection_details={data?.inspection_details}
                />
              ))}
            </Stack>
          ) : (
            <Stack alignItems="center" spacing={2} sx={{ textAlign: "center", py: 4 }}>
              <Typography variant="h6" color="textSecondary">
                {activeTab === 0
                  ? "There are no Pending Ads"
                  : "There are no Sold Ads"}
              </Typography>
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Ads;
