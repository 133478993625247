import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const fetchAuction = async () => {
    try {
        const response = await axiosInstance.get(API_ENDPOINTS.DEALER.GET_ALL_AUCTIONS);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const dealerAd = async (Id) => {
    try {
        const endpoint = API_ENDPOINTS.DEALER.GET_LISTING_DETAILS.replace(':listingId', Id);
        const response = await axiosInstance.get(endpoint);
        // const response = await axiosInstance.get(API_ENDPOINTS.LISTING.);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const dealerBid = async (listingId, amount) => {
    try {
        const endpoint = API_ENDPOINTS.DEALER.PLACE_BID.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint, { amount });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const dealerBidAd = async () => {
    try {
        const response = await axiosInstance.get(API_ENDPOINTS.DEALER.GET_ALL_BIDS_LISTING);
        return response.data;
    } catch (error) {
        throw error;
    }
};
