import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";
import { Icon } from '@iconify/react';

const PricingSection = ({ values, handleChange, errors, disabled = false }) => (
    <>
        <Typography variant="h4" mb={2} mt={2}>Pricing Setup</Typography>
        <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Typography variant="p1">Starting Bid Price* (USD)</Typography>
                        <Tooltip placement="top" title="The starting bid is set to 85% of the lower market recommendation. You can adjust it as needed.">
                            <IconButton size="small">
                                <Icon icon="mdi:help-circle-outline" color="green" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <TextInput
                        type="number"
                        label="Please Enter a starting bid price"
                        name="startingBid"
                        value={values.startingBid}
                        onChange={handleChange}
                        fullWidth
                        // error={!!errors.startingBid && !values.startingBid}
                        error={Boolean(errors.startingBid)}
                        helperText={errors.startingBid &&
                            (errors.startingBid !== "Starting bid is required" ? errors.startingBid : '')
                        }
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Typography variant="p1">Reserved Price* (USD)</Typography>
                        <Tooltip placement="top" title="The reserve price is set to the average of the market recommendation range to balance buyer interest. You can adjust it if needed.">
                            <IconButton size="small">
                                <Icon icon="mdi:help-circle-outline" color="green" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <TextInput
                        type="number"
                        label="Please Enter a Reserved price"
                        name="reservedBid"
                        value={values.reservedBid}
                        onChange={handleChange}
                        fullWidth
                        // error={!!errors.reservedBid && !values.reservedBid}
                        error={Boolean(errors.reservedBid)}
                        helperText={errors.reservedBid &&
                            (errors.reservedBid !== "Reserved Price is required" ? errors.reservedBid : '')
                        }
                        disabled={disabled}
                    />
                </Stack>
            </Grid>
        </Grid>
    </>
);

export default PricingSection;
