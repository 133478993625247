import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardMedia, Typography, IconButton, Box, Menu, MenuItem, Button, Stack, Divider, } from "@mui/material";
import { MoreVert as MoreVertIcon, AccessTime as AccessTimeIcon } from "@mui/icons-material";
import Styles from "./AdViewCard.module.scss";
import { Icon } from "@iconify/react";
import Modal from "../Modal";
import Toaster from "../Toaster";
import TextInput from "../TextInput";
// import { TimerProvider, useTimer } from "../../context/TimerContext"; 
import { AuctionTimer, TimerProviderWrapper } from "../../components/Timer"
import { goLive, updateReservedPrice, repostAd } from "../../api/services/consumerService";


const AdViewCard = ({ userId, modelYear, route, listingId, inspectionId, image, title, subtitle, price, details, time, status, menuItems = [], handleFetchAd, endTime, userRoleName, inspection_details }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [reservedPrice, setReservedPrice] = useState('');
  const open = Boolean(anchorEl);
  const navigate = useNavigate();


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewAD = () => {
    navigate(route);
  };

  const handleInspectionAd = () => {
    navigate(`/admin/ads/inspection/${inspectionId}`)
  };

  const handlers = {
    edit: () => navigate(`/ads/edit/${listingId}`),
    remove: () => console.log("Remove ad"),
    repost: async () => {
      await Toaster.handleApiCall(() => repostAd(listingId), "Ad reposted", handleFetchAd);
    },
    editPrice: () => setModalOpen(true),
    live: async () => {
      await Toaster.handleApiCall(() => goLive(listingId), "Ad status Live", handleFetchAd);
    },
  };

  const resetModal = () => {
    setModalOpen(false);
    setReservedPrice("");
  };

  const handleEditReservedPrice = async () => {
    await Toaster.handleApiCall(() => updateReservedPrice(listingId, reservedPrice),
      "Reserved Price Updated!",
      (response) => { handleFetchAd() }
    );
    resetModal();
  }

  const cardStyles = {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: { xs: "flex-start", sm: "center" },
    borderRadius: "12px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    padding: 2,
    mb: 2,
    maxWidth: "100%",
    position: "relative",
  };

  return (
    <TimerProviderWrapper endTime={endTime}>
      <Card sx={cardStyles}>
        {menuItems.length > 0 && (
          <IconButton
            onClick={handleMenuOpen}
            sx={{ position: "absolute", top: 8, right: 8, zIndex: 999, }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {menuItems.map(({ label, action }) => (
            <MenuItem
              key={action}
              onClick={() => {
                handlers[action]?.();
                handleMenuClose();
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>

        <CardMedia
          component="img"
          sx={{ width: "132px", height: "120px", borderRadius: "10px", objectFit: "cover" }}
          image={image}
          alt="car"
        />
        <Box
          sx={{ flex: 1, ml: { xs: 0, sm: 3 }, mt: { xs: 1, sm: 0 }, width: { xs: "100%", md: "auto" }, }}
        >
          <Stack marginBottom={2}>
            <Typography variant="h8" fontWeight="bold" mb={1}>
              {modelYear}  {title}
            </Typography>
            <Typography variant="p5" color="textSecondary">
              {subtitle}
              <Typography variant="p5" color="black" marginLeft={1}>
                {price}
              </Typography>
            </Typography>
            {/* <Stack direction="row" spacing={1} alignItems="center" mt={1} flexWrap="wrap">
              {details.map(({ key, icon, value }) => (
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  key={key}
                  className={Styles.detailCar}
                >
                  <Icon icon={icon} color="#22B14B" fontSize="large" />
                  <Typography variant="body4">
                    {key === "engine" ? `${value} CC` : key === "mileage" ? `${value} MI` : value}
                  </Typography>
                </Stack>
              ))}
            </Stack> */}
            <Stack direction="row" spacing={1} alignItems="center" mt={1} flexWrap="wrap">
              {details.map(({ key, icon, value }, index) => (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  key={key}
                  sx={{ display: 'flex', alignItems: 'center', }}
                >
                  <Icon icon={icon} color="#22B14B" fontSize="large" />
                  <Typography variant="body4">
                    {key === "engine" ? `${value} CC` : key === "mileage" ? `${value} MI` : value}
                  </Typography>

                  {index !== details.length - 1 && (
                    <Divider orientation="vertical" flexItem sx={{ height: 24, marginRight: 5 }} />
                  )}
                </Stack>
              ))}
            </Stack>
          </Stack>

          <Stack
            sx={{ gap: { xs: "20px", sm: "0" } }}
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "start", sm: "center" }}
            justifyContent="space-between"
          >
            {status !== "active" ? (
              <Stack marginBottom={{ xs: "10px", md: "0" }}>
                <Button variant="containedStatus"
                  sx={{
                    backgroundColor:
                      status === 'approved' ? 'primary.main' :
                        status === 'expired' ? '#FFCC00' :
                          status === 'sold' ? '#D3ECFF' :
                            status === 'rejected' ? '#FF4C4C' :
                              'third.secondary',
                  }}>
                  {status}
                </Button>
              </Stack>
            ) : (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mt={1}
                marginBottom={{ xs: "10px", md: "0" }}
              >
                <AccessTimeIcon color="success" fontSize="small" />
                <Typography variant="body2">
                  {/* {time} */}
                  <AuctionTimer />
                </Typography>
              </Stack>
            )}

            <Stack spacing={2}>
              {userRoleName && userRoleName === "admin" && status === "sold" && inspection_details !== null && (
                <Button
                  variant="filledSecondaryBlack"
                  color="secondary"
                  onClick={handleInspectionAd}
                >
                  View Inspection Form
                </Button>
              )}

              <Button
                variant="filledSecondaryBlack"
                color="secondary"
                onClick={handleViewAD}
                sx={{ width: '150px' }}
              >
                View Details
              </Button>
            </Stack>

          </Stack>
        </Box>
      </Card>

      <Modal
        open={modalOpen}
        onClose={resetModal}
        width={{ xs: '90%', sm: '50%', md: '40%', lg: '30%' }}
      >
        <Typography variant="h5">Edit Reserved Price</Typography>

        <Stack spacing={1} mt={'10%'}>
          <Typography variant="h7">Reserved Price</Typography>

          <TextInput
            name={reservedPrice}
            value={reservedPrice}
            type="number"
            label="Please Enter Reserved Price"
            fullWidth
            onChange={(e) => setReservedPrice(e.target.value)}
          />
        </Stack>
        <Stack mt={3} flexDirection="row" justifyContent="flex-end" gap="20px">
          <Button
            variant="outlinedCancel"
            onClick={resetModal}
            sx={{ width: 'auto', height: 'auto' }}
          >
            Cancel
          </Button>
          <Button
            variant="containedPrimary"
            onClick={handleEditReservedPrice}
            disabled={!reservedPrice.trim()}
            sx={{ width: 'auto', height: 'auto', textTransform: 'capitalize' }}
          >
            Update Price
          </Button>
        </Stack>
      </Modal>
    </TimerProviderWrapper>
  );
};

export default AdViewCard;
