import CongratsIcon from '../assets/icons/Congrats_icon.png';
import WarningIcon from '../assets/icons/Warning_icon.png';
import BidChange from '../assets/icons/Bid_change_icon.png';
import BidIcon from '../assets/icons/Bid_icon.png';
import PendingIcon from '../assets/icons/Pending_icon.png';
import RejectIcon from '../assets/icons/Reject_icon.png';
import { NOTIFICATION_TYPES } from './notificationType';
const { ADMIN, CONSUMER, DEALER } = NOTIFICATION_TYPES


export const NOTIFICATION_ICONS = {
    // Admin Notifications
    [ADMIN.ACTIVATE_ACCOUNT]: CongratsIcon,
    [ADMIN.DEACTIVATE_ACCOUNT]: RejectIcon,
    [ADMIN.ACCEPT_AD]: CongratsIcon,
    [ADMIN.REJECT_AD]: RejectIcon,
    [ADMIN.ACCEPT_INSPECTION]: CongratsIcon,
    [ADMIN.REJECT_INSPECTION]: RejectIcon,
    [ADMIN.PENDING_AD_APPROVAL]: PendingIcon,
    [ADMIN.PENDING_INSPECTION_APPROVAL]: PendingIcon,

    // Consumer Notifications
    [CONSUMER.AD_PENDING]: PendingIcon,
    [CONSUMER.AD_APPROVED]: CongratsIcon,
    [CONSUMER.AD_REJECTED]: RejectIcon,
    [CONSUMER.AD_LIVE]: CongratsIcon,
    [CONSUMER.AUCTION_ENDED_VIEW_BIDS]: BidChange,
    [CONSUMER.AUCTION_ENDED_HIGHEST_BID_ACCEPTED]: CongratsIcon,
    [CONSUMER.DEALER_COUNTER_OFFER]: WarningIcon,
    [CONSUMER.DEALER_REJECTED_OFFER]: RejectIcon,
    [CONSUMER.DEALER_ACCEPTED_OFFER]: CongratsIcon,
    [CONSUMER.AD_SOLD]: CongratsIcon,
    [CONSUMER.AD_SOLD_BY_ACCEPTING_BID]: CongratsIcon,
    [CONSUMER.DEALER_NEGOTIATION_OFFER]: WarningIcon,
    [CONSUMER.OFFER_ACCEPTED]: CongratsIcon,
    [CONSUMER.INSPECTION_APPROVED]: CongratsIcon,
    [CONSUMER.INSPECTION_REJECTED]: RejectIcon,
    [CONSUMER.PENDING_INSPECTION]: PendingIcon,


    // Dealer Notifications
    [DEALER.NEW_AD_AUCTION]: BidIcon,
    [DEALER.BID_OUTBID]: WarningIcon,
    [DEALER.BID_ACCEPTED_AUCTION_WON]: CongratsIcon,
    [DEALER.CONSUMER_NEGOTIATION_OFFER]: WarningIcon,
    [DEALER.OFFER_ACCEPTED]: CongratsIcon,
    [DEALER.COUNTER_OFFER_ACCEPTED]: WarningIcon,
};

export const DEFAULT_ICON = WarningIcon;
