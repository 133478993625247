import React, { createContext, useState, useEffect, useContext } from "react";
import { formatTime } from "../utils/dateTimeUtils";

const TimerContext = createContext();

export const TimerProvider = ({ children, endTime }) => {
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!endTime || isNaN(new Date(endTime).getTime())) {
            // console.error("Invalid 'endTime' provided to TimerProvider.");
            setLoading(false);
            setTimeRemaining(0);
            return;
        }

        const endTimestamp = new Date(endTime).getTime();

        const updateTimer = () => {
            const remainingTime = endTimestamp - Date.now();
            setTimeRemaining(remainingTime > 0 ? remainingTime : 0);
        };

        updateTimer();
        setLoading(false);

        const intervalId = setInterval(() => {
            updateTimer();
            if (endTimestamp - Date.now() <= 0) {
                clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [endTime]);

    return (
        <TimerContext.Provider value={{ timeRemaining, formatTime, loading }}>
            {children}
        </TimerContext.Provider>
    );
};

export const useTimer = () => {
    const context = useContext(TimerContext);
    if (!context) {
        throw new Error("useTimer must be used within a TimerProvider");
    }
    return context;
};
