import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";


const ExteriorConditionSection = ({ values, handleChange, errors, setFieldValue, makeOptions, modelOptions, transmissionOptions, engineTypeOptions, bodyTypeOptions, modelYearOptions, Options, fetchModel, disabled = false }) =>
(
    <>
        <Stack mt={1}></Stack>
        <Typography variant="p7" color="primary.main" fontWeight={600}>Exterior Condition</Typography>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Body Panels*</Typography>
                <RadioInput
                    name="exteriorCondition.bodyPanel"
                    value={values?.exteriorCondition?.bodyPanel}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.exteriorCondition?.bodyPanel && !values?.exteriorCondition?.bodyPanel}
                    helperText={errors?.exteriorCondition?.bodyPanel}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Windows/Glass*</Typography>
                <RadioInput
                    name="exteriorCondition.windowsGlass"
                    value={values.exteriorCondition?.windowsGlass}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.exteriorCondition?.windowsGlass && !values?.exteriorCondition?.windowsGlass}
                    helperText={errors?.exteriorCondition?.windowsGlass}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Lights*</Typography>
                <RadioInput
                    name="exteriorCondition.lights"
                    value={values.exteriorCondition?.lights}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.exteriorCondition?.lights && !values?.exteriorCondition?.lights}
                    helperText={errors?.exteriorCondition?.lights}
                    disabled={disabled}
                />
            </Stack>
        </Grid>


        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Mirrors*</Typography>
                <RadioInput
                    name="exteriorCondition.mirrors"
                    value={values?.exteriorCondition?.mirrors}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.exteriorCondition?.mirrors && !values?.exteriorCondition?.mirrors}
                    helperText={errors?.exteriorCondition?.mirrors}
                    disabled={disabled}
                />
            </Stack>
        </Grid>


        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Tires*</Typography>
                <RadioInput
                    name="exteriorCondition.tires"
                    value={values?.exteriorCondition?.tires}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.exteriorCondition?.tires && !values?.exteriorCondition?.tires}
                    helperText={errors?.exteriorCondition?.tires}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Bumpers*</Typography>
                <RadioInput
                    name="exteriorCondition.bumpers"
                    value={values?.exteriorCondition?.bumpers}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.exteriorCondition?.bumpers && !values?.exteriorCondition?.bumpers}
                    helperText={errors?.exteriorCondition?.bumpers}
                    disabled={disabled}
                />
            </Stack>
        </Grid>
    </>
)

export default ExteriorConditionSection