import React from 'react';
import { Menu, MenuItem, } from "@mui/material";

const SellNowMenu = ({ sellNowAnchorEl, handleMenuClose, handleSellNowOptionSelect, sellNowOptions }) => (
    <Menu
        anchorEl={sellNowAnchorEl}
        open={Boolean(sellNowAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
        {sellNowOptions.map((option) => (
            <MenuItem
                key={option.id}
                onClick={() => handleSellNowOptionSelect(option.id)}
                sx={{ display: "flex", flexDirection: "column", alignItems: "start", color: "#000000", fontSize: "14px", fontWeight: '400' }}
            >
                <span>{option.label}</span>
                <span style={{ fontSize: "14px", color: "#848484", fontWeight: "400" }}>{option.description}</span>
            </MenuItem>
        ))}
    </Menu>
);


export default SellNowMenu