import React from 'react';
import { Menu, Button } from "@mui/material";

const MobileMenu = ({ mobileMoreAnchorEl, handleMenuClose, handleNavigation, pages }) => (
    <Menu
        anchorEl={mobileMoreAnchorEl}
        open={Boolean(mobileMoreAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
        {pages.map((page) => (
            <Button key={page.name}
                onClick={() => {
                    handleNavigation(page.name);
                    handleMenuClose(); 
                    
                }}
                sx={{ my: 0, pr: 5, color: "black", display: "block" }}>
                {page.name}
            </Button>
        ))}
    </Menu>
);


export default MobileMenu