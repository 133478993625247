import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
// import { useNavigate } from 'react-router-dom';

const Sidebar = ({ selectedSection, setSelectedSection, menuItems }) => {
    // const navigate = useNavigate();

    const getListItemStyles = (section) => ({
        backgroundColor: selectedSection === section ? "#22B14B" : "inherit",
        justifyContent: "center",
        borderRadius: '8px',
        marginBottom: '0.5rem',
        '&:hover': { cursor: 'pointer', backgroundColor: selectedSection === section ? "#1E9F43" : "#f5f5f5", },
    });

    const handleNavigation = (section) => {
        setSelectedSection(section);
        // navigate(`/${section}`);
    };

    return (
        <List sx={{ width: { xs: 'auto', md: '100%' }, display: { xs: 'none', md: 'block' } }}>
            {menuItems.map(({ section, label }) => (
                <ListItem

                    key={section}
                    sx={getListItemStyles(section)}
                    onClick={() => handleNavigation(section)}
                >
                    <ListItemText
                        primary={
                            <Typography
                                sx={{
                                    color: selectedSection === section ? "white" : "black",
                                    textAlign: "left",
                                }}
                            >
                                {label}
                            </Typography>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default Sidebar;
