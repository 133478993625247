import * as React from "react";
import { Formik } from 'formik';
import Options from './data.json'
import toast from 'react-hot-toast';
import InfoIcon from "@mui/icons-material/Info";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createAd, fetchVinNo } from "../../../api/services/consumerService";
import { ImageUploadSection, PricingSection, OwnershipVerificationSection, VehicleDetailsSection, VehicleConditionSection } from '../../../components/Form';
import { Stack, Box, Typography, Container, Grid2 as Grid } from "@mui/material";
import { Button, TextInput, Checkbox, Toaster } from "../../../components";
import { getMake, getModelByMakeId, getTransmissionTypes, getBodyTypes, getEngineTypes, getFeatures } from "../../../api/services/listingService";
import validationSchema from "./ValidationSchema";

const CreateAd = () => {
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelptions] = useState([]);
  const [transmissionOptions, setTransmissionOptions] = useState([]);
  const [bodyTypeOptions, setBodyTypeOptions] = useState([]);
  const [engineTypeOptions, setEngineTypeOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);
  const [adData, setAdData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const vinNumber = location.state?.vinNumber || '';

  const handleBackClick = () => {
    navigate(-1);
  };

  const initialValues = adData ? {
    files: adData.images || [],
    startingBid: adData.startingBid || '',
    reservedBid: adData.reservedPrice || '',
    modelYear: adData.modelYear || '',
    make: adData.makes?.id || '',
    model: adData.models?.id || '',
    transmission: adData.transmission_types?.id || '',
    engineType: adData.engine_types?.id || '',
    bodyType: adData.body_types?.id || '',
    mileage: adData.mileage || '',
    location: adData.location || '',
    engineCapacity: adData.engineCapacity || '',
    seatCapacity: adData.seatCapacity || '',
    description: adData.description || '',
    exteriorColor: adData.exteriorColor || '',
    interiorColor: adData.interiorColor || '',
    loanOrLeaseStatus: adData.loanOrLeaseStatus || '',
    exteriorDamagesStatus: adData.exteriorDamagesStatus || '',
    exteriorDamage: adData.exteriorDamage || '',
    interiorDamagesStatus: adData.interiorDamagesStatus || '',
    interiorDamage: adData.interiorDamage || '',
    mechanicalIssuesStatus: adData.mechanicalIssuesStatus || '',
    mechanicalIssues: adData.mechanicalIssues || '',
    modificationStatus: adData.modificationStatus || '',
    modification: adData.modification || '',
    keyFobs: adData.keyFobs || '',
    tireCondition: adData.tireCondition || '',
    conditionRating: adData.conditionRating || '',
    driversLicense: adData.driversLicense || [],
    vehicleRegistration: adData.vehicleRegistration || [],
    // features: adData.features || []
    features: adData.features ? adData.features.map(feature => feature.id) : [],
    companyName: adData.companyName || '',
    dueAmount: adData.dueAmount || '',
    accidentHistory: {
      count: adData.accidentHistory?.count || '',
      severity: adData.accidentHistory?.severity || '',
    }
  } : {
    files: [], reservedBid: '', modelYear: '', make: '', model: '', transmission: '', engineType: '', bodyType: '', mileage: '',
    location: '', engineCapacity: '', seatCapacity: '', description: '', features: [], exteriorColor: '', interiorColor: '',
    loanOrLeaseStatus: '', exteriorDamagesStatus: '', exteriorDamage: [], interiorDamagesStatus: '', interiorDamage: [],
    mechanicalIssuesStatus: '', mechanicalIssues: [], modification: '', modificationStatus: '', keyFobs: '', startingBid: '',
    tireCondition: '', conditionRating: '', driversLicense: [], vehicleRegistration: [], companyName: '', dueAmount: '',
    accidentHistory: {
      count: '',
      severity: '',
    }
  };
  // console.log('initialValues', initialValues)

  const handleCreateAD = async (values) => {
    console.log("Values", values);
    const formData = new FormData();
    formData.append("reservedPrice", parseFloat(values.reservedBid));
    formData.append("modelYear", values.modelYear);
    formData.append("make", values.make);
    formData.append("model", values.model);
    formData.append("transmissionType", values.transmission);
    formData.append("engineType", values.engineType);
    formData.append("bodyType", parseInt(values.bodyType));
    formData.append("mileage", parseFloat(values.mileage));
    formData.append("location", values.location);
    formData.append("engineCapacity", parseFloat(values.engineCapacity));
    formData.append("seatCapacity", values.seatCapacity);
    formData.append("description", values.description);
    formData.append("featureIds", JSON.stringify(values.features));
    formData.append("startingBid", parseFloat(values.startingBid));
    formData.append("exteriorColor", values.exteriorColor);
    formData.append("interiorColor", values.interiorColor);
    formData.append("loanOrLeaseStatus", values.loanOrLeaseStatus);
    formData.append("accidentHistory", JSON.stringify(values.accidentHistory));
    formData.append("condition", values.conditionRating);
    if (["loan", "lease"].includes(values.loanOrLeaseStatus)) {
      formData.append("companyName", values.companyName);
      formData.append("dueAmount", parseFloat(values.dueAmount));
    }
    formData.append("exteriorDamage", values.exteriorDamagesStatus === "yes" ? JSON.stringify(values.exteriorDamage) : JSON.stringify([]));
    formData.append("interiorDamage", values.interiorDamagesStatus === "yes" ? JSON.stringify(values.interiorDamage) : JSON.stringify([]));
    formData.append("mechanicalIssues", values.mechanicalIssuesStatus === "yes" ? JSON.stringify(values.mechanicalIssues) : JSON.stringify([]));
    formData.append("modifications", values.modificationStatus === "yes" ? values.modification : "");
    formData.append("keyFobs", values.keyFobs);
    formData.append("tireCondition", values.tireCondition);
    values.files.forEach((file, index) => formData.append("files", file));
    values.driversLicense.forEach((file, index) => formData.append("driversLicense", file));
    values.vehicleRegistration.forEach((file, index) => formData.append("vehicleRegistration", file));

    await Toaster.handleApiCall(() => createAd(formData),
      "Form submitted successfully.",
      (response) => {
        navigate('/ads')
      }
    );
  };

  const fetchModel = async (makeId) => {
    try {
      console.log("makeId", makeId)
      const data = await getModelByMakeId(makeId);
      const options = data?.data?.map((item) => ({
        value: item.id,
        label: item.name
      }));
      setModelptions(options);
    } catch (error) {
      console.error("Error fetching model data:", error);
    }
  };

  const fetchOptions = async (fetchFunction, setOptions, errorMsg) => {
    try {
      const data = await fetchFunction();
      const options = data?.data?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(options);
    } catch (error) {
      console.error(`Error fetching ${errorMsg} data:`, error);
    }
  };

  const fetchData = async () => {
    try {
      // console.log("vinNumber", vinNumber)
      const response = await fetchVinNo(vinNumber);
      // console.log("fetchVinNo API", response.data);
      const data = response?.data || {};
      setAdData(data);

      if (data.makes?.id) {
        fetchModel(data.makes.id);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const modelYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1990;
    const options = [];

    for (let year = currentYear; year >= startYear; year--) {
      options.push({ value: year, label: year.toString() });
    }

    return options;
  }

  useEffect(() => {
    if (vinNumber) {
      // console.log('vinNumber',vinNumber)
      fetchData();
    }
  }, [vinNumber])

  useEffect(() => {
    fetchOptions(getMake, setMakeOptions, "make");
    fetchOptions(getTransmissionTypes, setTransmissionOptions, "transmission");
    fetchOptions(getBodyTypes, setBodyTypeOptions, "body type");
    fetchOptions(getEngineTypes, setEngineTypeOptions, "engine type");
    fetchOptions(getFeatures, setFeaturesOptions, "features");
  }, []);

  return (
    <>
      <Container >
        <Grid container sx={{ width: "100%" }}>
          <Grid size={{ xs: 12 }} sx={{ width: "100%" }}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              // validateOnMount={false}
              onSubmit={handleCreateAD}
            >

              {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors, isValid, dirty }) => (
                <form onSubmit={handleSubmit} autoComplete="off" autoCapitalize="off">
                  <Stack sx={{ boxShadow: "0px 1px 7px 0px #D1D1D1", borderTop: "3px solid #22B14B", padding: { xs: "10px", md: "40px" } }}>
                    <Stack
                      spacing={2}
                      sx={{ marginTop: { xs: "30px" } }}
                    >

                      <ImageUploadSection
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />

                      <VehicleDetailsSection
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        fetchModel={fetchModel}
                        makeOptions={makeOptions}
                        modelOptions={modelOptions}
                        transmissionOptions={transmissionOptions}
                        engineTypeOptions={engineTypeOptions}
                        bodyTypeOptions={bodyTypeOptions}
                        modelYearOptions={modelYearOptions()}
                        Options={Options}
                      />

                      <VehicleConditionSection
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        Options={Options}
                      />

                      <Typography variant="p1">Features</Typography>
                      <Box>
                        {/* <Stack direction={'row'} flexWrap={'wrap'} width={'50%'}> */}
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          flexWrap="wrap"
                          sx={{ width: { xs: '100%', md: '60%' } }}
                        >
                          <Checkbox
                            sx={{ width: { xs: '100%', md: '48%' } }}
                            options={featuresOptions}
                            selectedValues={values.features}
                            onChange={(newFeatures) => setFieldValue("features", newFeatures)}
                            error={errors.features && touched.features}
                            helperText={errors.features && touched.features && values.features.length === 0 ? errors.features : ''}
                          />
                        </Stack>
                      </Box>

                      <PricingSection
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                      />

                      <OwnershipVerificationSection
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />

                      <Typography variant="p1">Description</Typography>
                      <TextInput
                        label="Write description about your car"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        // minRows="4"
                        rows={5}
                      />
                    </Stack>
                  </Stack>

                  <Box display="flex" alignItems="center" border="1px solid" borderColor="#3FBE61" bgcolor="#EBF7EE" borderRadius="8px" padding={2} mt={5}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <InfoIcon color="success" fontSize="large" />

                      <Stack>
                        <Typography fontWeight="bold" color="text.primary">
                          Note:
                        </Typography>
                        <Typography variant="h10" color="third.primary">
                          All auctions are set to a <Typography component="span" color="success.main" fontWeight="bold">2-hour</Typography> duration. Listings require up to <Typography component="span" color="success.main" fontWeight="bold">24 hours</Typography> for verification before going live; you'll receive the notification once approved.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>

                  <Stack mt={4} flexDirection={"row"} justifyContent="flex-end" gap={'20px'}>
                    <Button
                      variant="outlined"
                      color="transparent"
                      sx={{ width: '140px', }}
                      onClick={handleBackClick}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      // fullWidth
                      // disabled={!(isValid && dirty)}
                      sx={{ width: '230px', textTransform: 'capitalize' }}
                      onClick={(e) => {
                        if (!isValid && dirty) {
                          toast.dismiss();
                          toast.error('Please fill out all required fields');
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default CreateAd
