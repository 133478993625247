import * as Yup from "yup";

const emailValidation = Yup.string()
    .matches(/^(?!\s)(.*\S)?$/, 'Email must not start or end with spaces')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email is not valid")
    .max(50, 'Email must not exceed 50 characters')
    .required('Email is required')

const passwordValidation = Yup.string()
    .matches(/^(?!\s)(.*\S)?$/, 'Password must not start or end with spaces')
    .matches(/^\S*$/, 'Password must not contain spaces')
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')

const fullNameValidation = Yup.string()
    .matches(/^(?!\s)(.*\S)?$/, 'Full name must not start or end with spaces')
    .matches(/^[^0-9]*$/, 'Full name must not contain numbers')
    .matches(/^[A-Za-z\s']+$/, 'Full name must not contain special characters')
    .max(50, 'Full name must not exceed 50 characters')
    .required('Full name is required');

const addressValidation = Yup.string()
    .matches(/^(?!\s)(.*\S)?$/, 'Address must not start or end with spaces')
    .max(200, 'Address must not exceed 200 characters')
    .required('Address is required')

const phoneNumberValidation = Yup.string()
    .matches(/^\+1[0-9]{10}$/, 'Phone number must contain 10 digits')
    .required('Phone number is required');

const cnicValidation = Yup.string()
    .trim("CNIC must not contain spaces")
    .required('CNIC is required');

const licenseValidation = Yup.string()
    .trim("license must not contain spaces")
    .required('License No is required');

const confirmPasswordValidation = Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required');


const isImage = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    return imageExtensions.includes(fileExtension);
};

// const reservedBidValidation = Yup.string().required("Reserved bid is required")
const reservedBidValidation = Yup.number()
    .required("Reserved Price is required")
    .typeError("Reserved Price must be a number")
    .positive("Reserved Price must be a positive number");

const startingBidValidation = Yup.number()
    .required("Starting bid is required")
    .typeError("Starting bid must be a number")
    .positive("Starting bid must be a positive number");

const auctionTimeValidation = Yup.string().required('Auction Time is required');

const makeValidation = Yup.string().required('Make is required');

const modelValidation = Yup.string().required('Model is required');

const modelYearValidation = Yup.string().required('Model Year is required');

const transmissionValidation = Yup.string().required('Transmission is required');

const engineTypeValidation = Yup.string().required('Engine Type is required');

const bodyTypeValidation = Yup.string().required('Body Type is required');

// const mileageValidation = Yup.string().required("Mileage is required")
const mileageValidation = Yup.number()
    .required("Mileage is required")
    .typeError("Mileage must be a number")
    .positive("Mileage must be a positive number");

const locationValidation = Yup.string().required("Location is required")

// const engineCapacityValidation = Yup.string().required("Engine capacity is required")
const engineCapacityValidation = Yup.number()
    .required("Engine capacity is required")
    .typeError("Engine capacity must be a number")
    .positive("Engine capacity must be a positive number");

const seatCapacityValidation = Yup.string().required('Seat Capacity is required');

const exteriorColorValidation = Yup.string()
    .matches(/^[a-zA-Z]+$/, 'Exterior Color should be in letters only')
    .required('Exterior Color is required');

const interiorColorValidation = Yup.string()
    .matches(/^[a-zA-Z]+$/, 'Interior Color should be in letters only')
    .required('Interior Color is required');

const featuresValidation = Yup.array().min(1, 'Please select atleast one feature');

const filesValidation = Yup.array().min(1, 'Please upload at least one image');

const loanOrLeaseStatusValidation = Yup.string()
    .required('Please select loan or lease status');  // Always required

const companyNameValidation = Yup.string()
    .test('required-company-name', 'Company name is required', function (value) {
        const { loanOrLeaseStatus } = this.parent;
        if (loanOrLeaseStatus === 'loan' || loanOrLeaseStatus === 'lease') {
            return !!value;
        }
        return true;
    });

const dueAmountValidation = Yup.number()
    .typeError('Amount must be a number')
    .test('required-due-amount', 'Remaining balance or payoff amount is required', function (value) {
        const { loanOrLeaseStatus } = this.parent;
        if (loanOrLeaseStatus === 'loan' || loanOrLeaseStatus === 'lease') {
            return value > 0;
        }
        return true;
    });

const keyFobsValidation = Yup.string().required('Please select the number of key fobs');

const exteriorDamagesStatusValidation = Yup.string()
    .required('Please select Exterior Damage Status');

const exteriorDamagesValidation = Yup.array()
    .test('required-exterior-damages', 'Please select at least one exterior damage', function (value) {
        const { exteriorDamagesStatus } = this.parent;
        if (exteriorDamagesStatus === 'yes') {
            return value && value.length > 0;
        }
        return true;
    });

const interiorDamagesStatusValidation = Yup.string()
    .required('Please select Interior Damage Status');

const interiorDamagesValidation = Yup.array()
    .test('required-interior-damages', 'Please select at least one interior damage', function (value) {
        const { interiorDamagesStatus } = this.parent;
        if (interiorDamagesStatus === 'yes') {
            return value && value.length > 0;
        }
        return true;
    });

const mechanicalIssuesStatusValidation = Yup.string()
    .required('Please select Mechanical Issues Status');

const mechanicalIssuesValidation = Yup.array()
    .test('required-mechanical-Issues', 'Please select at least one mechanical issue', function (value) {
        const { mechanicalIssuesStatus } = this.parent;
        if (mechanicalIssuesStatus === 'yes') {
            return value && value.length > 0;
        }
        return true;
    });

const modificationStatusValidation = Yup.string()
    .required('Please select Modification or Aftermarket Parts');

const modificationValidation = Yup.string()
    .test('required-modification-details', 'Please enter modification details', function (value) {
        const { modificationStatus } = this.parent;
        // If modificationStatus is 'yes', ensure modification has a value
        if (modificationStatus === 'yes') {
            return !!value && value.trim().length > 0; // Ensures modification details are entered
        }
        return true; // If 'no', modification details are not required
    });

const tireConditionValidation = Yup.string()
    .required('Please select Tire Condition');

const conditionRatingValidation = Yup.string()
    .required('Please select Condition Rating');

// const accidentHistoryValidation = Yup.string()
//     .required('Please select Accident History');

const accidentHistoryValidation = Yup.object({
    count: Yup.string().required('Please select Accident History count'),   // Nested validation for count
    severity: Yup.string().test(
        'required-severity',
        'Please specify severity for the accident',
        function (value) {
            const { count } = this.parent;
            if (count === '1' || count === '2') {
                return !!value;
            }
            return true;
        }
    ),
});

const inspectorNameValidation = Yup.string()
    .required('Please Enter Inspector Name');


const inspectorDateValidation = Yup.date()
    .required('Inspection date is required');

const licensePlateValidation = Yup.string()
    .required('Please Enter License Plate No.');

const exteriorConditionValidation = Yup.object({
    bodyPanel: Yup.string().required('Please select Body Panels'),
    windowsGlass: Yup.string().required('Please select Windows/Glass'),
    lights: Yup.string().required('Please select lights'),
    mirrors: Yup.string().required('Please select Mirrors condition'),
    tires: Yup.string().required('Please select Tires condition'),
    bumpers: Yup.string().required('Please select Bumpers condition'),
});

const interiorConditionValidation = Yup.object({
    upholsterySeats: Yup.string().required('Please select Upholstery/Seats Condition'),
    dashboard: Yup.string().required('Please select Dashboard condition'),
    carpetMats: Yup.string().required('Please select Carpet/Mats condition'),
    headliner: Yup.string().required('Please select Headliner condition'),
    airconditioningHeating: Yup.string().required('Please select Air Conditioning/Heating condition'),
    entertainmentSystem: Yup.string().required('Please select Entertainment System condition'),
});

const mechanicalConditionValidation = Yup.object({
    engine: Yup.string().required('Please select Engine Condition'),
    transmission: Yup.string().required('Please select Transmission condition'),
    brakes: Yup.string().required('Please select Brakes condition'),
    steering: Yup.string().required('Please select Steering condition'),
    suspension: Yup.string().required('Please select Suspension condition'),
    exhaustSystem: Yup.string().required('Please select Exhaust System condition'),
    battery: Yup.string().required('Please select Battery condition'),
});

const safetyFeaturesValidation = Yup.object({
    airbags: Yup.string().required('Please select Airbags Condition'),
    seatBelts: Yup.string().required('Please select Seat belts condition'),
});

const additionalChecksValidation = Yup.object({
    documentPaperwork: Yup.string().required('Please select Documents & paper work  Condition'),
});

const inspectorCommentsValidation = Yup.string().required('Inspection description must be prvided');

export {
    licenseValidation,
    phoneNumberValidation,
    cnicValidation,
    addressValidation,
    fullNameValidation,
    passwordValidation,
    emailValidation,
    confirmPasswordValidation,
    reservedBidValidation,
    auctionTimeValidation,
    modelYearValidation,
    makeValidation,
    modelValidation,
    transmissionValidation,
    engineTypeValidation,
    bodyTypeValidation,
    mileageValidation,
    locationValidation,
    engineCapacityValidation,
    seatCapacityValidation,
    exteriorColorValidation,
    interiorColorValidation,
    featuresValidation,
    filesValidation,
    startingBidValidation,
    loanOrLeaseStatusValidation,
    companyNameValidation,
    dueAmountValidation,
    keyFobsValidation,
    exteriorDamagesStatusValidation,
    interiorDamagesStatusValidation,
    mechanicalIssuesStatusValidation,
    modificationStatusValidation,
    tireConditionValidation,
    conditionRatingValidation,
    accidentHistoryValidation,
    exteriorDamagesValidation,
    interiorDamagesValidation,
    mechanicalIssuesValidation,
    modificationValidation,
    inspectorNameValidation,
    inspectorDateValidation,
    licensePlateValidation,
    exteriorConditionValidation,
    interiorConditionValidation,
    mechanicalConditionValidation,
    safetyFeaturesValidation,
    additionalChecksValidation,
    inspectorCommentsValidation,
    isImage
}