import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";


const SafetyFeatureSection = ({ values, handleChange, errors, setFieldValue, makeOptions, modelOptions, transmissionOptions, engineTypeOptions, bodyTypeOptions, modelYearOptions, Options, fetchModel, disabled = false }) =>
(
    <>
        <Typography variant="p7" color="primary.main" fontWeight={600}>Safety Features</Typography>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Airbags*</Typography>
                <RadioInput
                    name="safetyFeatures.airbags"
                    value={values.safetyFeatures?.airbags}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.safetyFeatures?.airbags && !values?.safetyFeatures?.airbags}
                    helperText={errors?.safetyFeatures?.airbags}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Seat Belts*</Typography>
                <RadioInput
                    name="safetyFeatures.seatBelts"
                    value={values.safetyFeatures?.seatBelts}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors?.safetyFeatures?.seatBelts && !values?.safetyFeatures?.seatBelts}
                    helperText={errors?.safetyFeatures?.seatBelts}
                    disabled={disabled}
                />
            </Stack>
        </Grid>
    </>
)
export default SafetyFeatureSection