// import React from 'react';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { TextField } from '@mui/material';

// const DateTimePicker = ({
//   label,
//   value,
//   onChange,
//   error,
//   helperText,
//   disabled = false,
//   name,
// }) => {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <MUIDateTimePicker
//         placeholder={label}
//         value={value}
//         onChange={(newValue) => onChange(name, newValue)}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             fullWidth
//             error={!!error}
//             helperText={helperText}
//             disabled={disabled}
//           />
//         )}
//       />
//     </LocalizationProvider>
//   );
// };

// export default DateTimePicker;



import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';

const DateTimePicker = ({ label, value, onChange, error, helperText, disabled = false, name, }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDateTimePicker
        placeholder={label}
        value={value}
        disabled= {disabled}
        onChange={(newValue) => onChange(name, newValue)}
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!error,
            helperText: helperText,
            disabled: disabled,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
