import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";


const InteriorConditionSection = ({ values, handleChange, errors, setFieldValue, makeOptions, modelOptions, transmissionOptions, engineTypeOptions, bodyTypeOptions, modelYearOptions, Options, fetchModel, disabled = false }) =>
(
    <>
        <Typography variant="p7" color="primary.main" fontWeight={600}>Interior Condition</Typography>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Upholstery/Seats*</Typography>
                <RadioInput
                    name="interiorCondition.upholsterySeats"
                    value={values.interiorCondition?.upholsterySeats}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors.interiorCondition?.upholsterySeats && !values.interiorCondition?.upholsterySeats}
                    helperText={errors.interiorCondition?.upholsterySeats}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Dashboard*</Typography>
                <RadioInput
                    name="interiorCondition.dashboard"
                    value={values.interiorCondition?.dashboard}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors.interiorCondition?.dashboard && !values.interiorCondition?.dashboard}
                    helperText={errors.interiorCondition?.dashboard}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Carpets/Mats*</Typography>
                <RadioInput
                    name="interiorCondition.carpetMats"
                    value={values.interiorCondition?.carpetMats}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors.interiorCondition?.carpetMats && !values.interiorCondition?.carpetMats}
                    helperText={errors.interiorCondition?.carpetMats}
                    disabled={disabled}
                />
            </Stack>
        </Grid>


        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Headliner*</Typography>
                <RadioInput
                    name="interiorCondition.headliner"
                    value={values.interiorCondition?.headliner}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors.interiorCondition?.headliner && !values.interiorCondition?.headliner}
                    helperText={errors.interiorCondition?.headliner}
                    disabled={disabled}
                />
            </Stack>
        </Grid>


        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Air Conditioning/Heating*</Typography>
                <RadioInput
                    name="interiorCondition.airconditioningHeating"
                    value={values.interiorCondition?.airconditioningHeating}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors.interiorCondition?.airconditioningHeating && !values.interiorCondition?.airconditioningHeating}
                    helperText={errors.interiorCondition?.airconditioningHeating}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Entertainment System*</Typography>
                <RadioInput
                    name="interiorCondition.entertainmentSystem"
                    value={values.interiorCondition?.entertainmentSystem}
                    options={Options?.inspectionOptions}
                    onChange={handleChange}
                    error={!!errors.interiorCondition?.entertainmentSystem && !values.interiorCondition?.entertainmentSystem}
                    helperText={errors.interiorCondition?.entertainmentSystem}
                    disabled={disabled}
                />
            </Stack>
        </Grid>
    </>
)
export default InteriorConditionSection