import * as React from "react";
import { Formik } from 'formik';
import Options from './data.json'
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Stack, Typography, Container, Grid2 as Grid, capitalize } from "@mui/material";
import { Button, TextInput, Toaster, SelectInput, RadioInput, DateTimePicker, FileUpload, Modal } from "../../../components";
import { getMake, getModelByMakeId, } from "../../../api/services/listingService";
import validationSchema from "./ValidationSchema";
import dayjs from 'dayjs';
import ExteriorConditionSection from "../../../components/Form/ExteriorConditionSection";
import InteriorConditionSection from "../../../components/Form/InteriorConditionSection";
import MechanicalConditionSection from "../../../components/Form/MechanicalConditionSection";
import SafetyFeatureSection from "../../../components/Form/SafetyFeatureSection";
import { viewInspection, updateInspectionStatus } from "../../../api/services/adminService";
import { listIcons } from "@iconify/react/dist/iconify.js";

const AdminInspectionForm = () => {
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelptions] = useState([]);
    const [adData, setAdData] = useState(null);
    // const location = useLocation();
    const navigate = useNavigate();
    const { inspectionId } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [reason, setReason] = useState('');

    const initialValues = adData ? {
        modelYear: adData?.vehicle_details?.modelYear || '',
        make: adData?.makes?.id || '',
        model: adData?.models?.id || '',
        mileage: adData?.vehicle_details?.mileage || '',
        // licensePlate: adData?.lisencePlate || '',
        driversLicense: adData?.driversLicense ? [adData?.driversLicense] : [],
        inspectorName: adData?.inspection_details?.inspectorName || '',
        inspectionDate: adData?.inspection_details?.inspectionDate ? dayjs(adData.inspection_details?.inspectionDate) : dayjs(),
        exteriorCondition: {
            bodyPanel: adData?.inspection_details?.exteriorCondition?.bodyPanels || '',
            windowsGlass: adData?.inspection_details?.exteriorCondition?.windowGlass || '',
            lights: adData?.inspection_details?.exteriorCondition?.lights || '',
            mirrors: adData?.inspection_details?.exteriorCondition?.mirrors || '',
            tires: adData?.inspection_details?.exteriorCondition?.tires || '',
            bumpers: adData?.inspection_details?.exteriorCondition?.bumpers || ''
        },
        interiorCondition: {
            upholsterySeats: adData?.inspection_details?.interiorCondition?.upholstery || '',
            dashboard: adData?.inspection_details?.interiorCondition?.dashboard || '',
            carpetMats: adData?.inspection_details?.interiorCondition?.carpetsMats || '',
            headliner: adData?.inspection_details?.interiorCondition?.headliner || '',
            airconditioningHeating: adData?.inspection_details?.interiorCondition?.airConditioningHeating || '',
            entertainmentSystem: adData?.inspection_details?.interiorCondition?.entertainmentSystem || ''
        },
        mechanicalCondition: {
            engine: adData?.inspection_details?.mechanicalCondition?.engine || '',
            transmission: adData?.inspection_details?.mechanicalCondition?.transmission || '',
            brakes: adData?.inspection_details?.mechanicalCondition?.brakes || '',
            steering: adData?.inspection_details?.mechanicalCondition?.steering || '',
            suspension: adData?.inspection_details?.mechanicalCondition?.suspension || '',
            exhaustSystem: adData?.inspection_details?.mechanicalCondition?.exhaustSystem || '',
            battery: adData?.inspection_details?.mechanicalCondition?.battery || ''
        },
        safetyFeatures: {
            airbags: adData?.inspection_details?.safetyFeatures?.airbags || '',
            seatBelts: adData?.inspection_details?.safetyFeatures?.seatbelts || ''
        },
        additionalChecks: { documentPaperwork: adData?.inspection_details?.additionalChecks?.documents || '' },
        inspectorComments: adData?.inspection_details?.comments || '',
    } : {
        modelYear: '', make: '', model: '', mileage: '', licensePlate: '', inspectorName: '', inspectionDate: dayjs(), inspectorComments: '', driversLicense: [],
        exteriorCondition: { bodyPanel: '', windowsGlass: '', lights: '', mirrors: '', tires: '', bumpers: '' },
        interiorCondition: { upholsterySeats: '', dashboard: '', carpetMats: '', headliner: '', airconditioningHeating: '', entertainmentSystem: '' },
        mechanicalCondition: { engine: '', transmission: '', brakes: '', steering: '', suspension: '', exhaustSystem: '', battery: '' },
        safetyFeatures: { airbags: '', seatBelts: '' }, additionalChecks: { documentPaperwork: '' }
    };

    // console.log('initialValues', initialValues)

    const handleRejectInspection = async () => {
        const status = "rejected"
        await Toaster.handleApiCall(() => updateInspectionStatus(inspectionId, status, reason),
            "Inspection form rejected!",
            (response) => {
                navigate("/admin/ads");
            }
        );
        setModalOpen(false); // Close the modal after submission
        setReason('');
    };

    const handleInspectionForm = async () => {
        // console.log("Values", values);

        const status = "approved"
        await Toaster.handleApiCall(() => updateInspectionStatus(inspectionId, status),
            "Inspection form accepted!",
            (response) => {
                navigate('/admin/ads')
            }
        );
    };

    const fetchModel = async (makeId) => {
        try {
            console.log("makeId", makeId)
            const data = await getModelByMakeId(makeId);
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setModelptions(options);
        } catch (error) {
            console.error("Error fetching model data:", error);
        }
    };

    const fetchOptions = async (fetchFunction, setOptions, errorMsg) => {
        try {
            const data = await fetchFunction();
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setOptions(options);
        } catch (error) {
            console.error(`Error fetching ${errorMsg} data:`, error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await viewInspection(inspectionId);
            console.log("viewInspection API", response.data);
            const data = response?.data || {};
            setAdData(data);

            if (data.makes?.id) {
                fetchModel(data.makes.id);
            }
        } catch (error) {
            console.error("Error fetching ad details:", error);
        }
    };

    const modelYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1990;
        const options = [];

        for (let year = currentYear; year >= startYear; year--) {
            options.push({ value: year, label: year.toString() });
        }

        return options;
    }

    useEffect(() => {
        console.log('inspectionIdinspectionId', inspectionId)
        if (inspectionId) {
            fetchData();
        }
    }, [inspectionId])

    useEffect(() => {
        fetchOptions(getMake, setMakeOptions, "make");
    }, []);

    return (
        <>
            <Container >
                <Grid container sx={{ width: "100%" }}>
                    <Grid size={{ xs: 12 }} sx={{ width: "100%" }}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            // validateOnMount={false}
                            onSubmit={handleInspectionForm}
                        >

                            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors, isValid, dirty }) => (
                                <form onSubmit={handleSubmit} autoComplete="off" autoCapitalize="off">
                                    <Stack sx={{ boxShadow: "0px 1px 7px 0px #D1D1D1", borderTop: "3px solid #22B14B", padding: { xs: "10px", md: "40px" } }}>
                                        <Stack
                                            spacing={2}
                                            sx={{ marginTop: { xs: "30px" } }}
                                        >
                                            {adData?.inspection_details?.status !== "pending" && (
                                                <Stack direction="row" justifyContent={"center"} backgroundColor='third.secondary'>
                                                    {/* <Typography variant="h10" fontSize={20}>
                                                        Inspection has been <Typography variant="h10" fontSize={26}>{adData?.inspection_details?.status}</Typography>
                                                    </Typography> */}
                                                    <Typography variant="body1" fontSize={26} fontWeight={700}>
                                                        Inspection has been {adData?.inspection_details?.status}
                                                    </Typography>
                                                </Stack>
                                            )}

                                            <Typography variant="h4" mb={2} mt={2}>
                                                Inspection Form
                                            </Typography>

                                            <Grid container spacing={2}>
                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Inspector Name*</Typography>
                                                        <TextInput
                                                            label="Please Enter"
                                                            name="inspectorName"
                                                            value={values.inspectorName}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            error={!!errors.inspectorName && !values.inspectorName}
                                                            disabled={true}
                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Inspection Date*</Typography>
                                                        <DateTimePicker
                                                            label="Inspection Date"
                                                            name="inspectionDate"
                                                            // value={values.inspectionDate}
                                                            value={values.inspectionDate || dayjs()}
                                                            onChange={setFieldValue}
                                                            error={touched.inspectionDate && !!errors.inspectionDate}
                                                            disabled={true}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Typography variant="body2" fontSize={20} fontWeight={700} mb={2} mt={2}>
                                                Vehicle Information
                                            </Typography>

                                            <Grid container spacing={2}>
                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Make*</Typography>
                                                        <SelectInput
                                                            name="make"
                                                            label="Please Select"
                                                            value={values.make}
                                                            onChange={(event) => {
                                                                const selectedMake = event.target.value;
                                                                setFieldValue("make", selectedMake);
                                                                fetchModel(selectedMake);
                                                            }}
                                                            options={makeOptions}
                                                            error={!!errors.make && !values.make}
                                                            disabled={true}

                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Model*</Typography>
                                                        <SelectInput
                                                            name="model"
                                                            label="Please Select"
                                                            value={values.model}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            disabled={!values.make || true}
                                                            options={modelOptions}
                                                            error={!!errors.model && !values.model}

                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Model Year*</Typography>
                                                        <SelectInput
                                                            name="modelYear"
                                                            label="Please Select"
                                                            value={values.modelYear}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            options={modelYearOptions()}
                                                            error={!!errors.modelYear && !values.modelYear}
                                                            disabled={true}

                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Mileage*</Typography>
                                                        <TextInput
                                                            label="Please Enter"
                                                            name="mileage"
                                                            value={values.mileage}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            // error={!!errors.mileage && !values.mileage}
                                                            error={Boolean(errors.mileage)}
                                                            helperText={errors.mileage &&
                                                                (errors.mileage !== "Mileage is required" ? errors.mileage : '')
                                                            }
                                                            disabled={true}

                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid size={{ xs: 12, md: 6 }}>
                                                <FileUpload
                                                    name="driversLicense"
                                                    placeholder="Upload Driver's License"
                                                    label="Driver's License*"
                                                    maxImages={1}
                                                    value={values.driversLicense}
                                                    setFieldValue={setFieldValue}
                                                    error={errors.driversLicense && touched.driversLicense}
                                                    helperText={errors.driversLicense && touched.driversLicense && values.driversLicense.length === 0 ? errors.driversLicense : ''}
                                                    disabled={true}
                                                // error={errors.driversLicense && touched.driversLicense}
                                                // helperText={errors.driversLicense && touched.driversLicense ? errors.driversLicense : "Please upload a valid driver's license."}
                                                />
                                            </Grid>

                                            <ExteriorConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                                disabled={true}
                                            />

                                            <InteriorConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                                disabled={true}
                                            />

                                            <MechanicalConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                                disabled={true}
                                            />

                                            <SafetyFeatureSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                                disabled={true}
                                            />

                                            <Typography variant="p7" color="primary.main" fontWeight={600}>Additional Checks</Typography>

                                            <Grid size={{ xs: 12, md: 12 }}>
                                                <Stack spacing={1} sx={{ width: "100%" }}>
                                                    <Typography variant="p1">Documents & Paperwork*</Typography>
                                                    <RadioInput
                                                        name="additionalChecks.documentPaperwork"
                                                        value={values.additionalChecks?.documentPaperwork}
                                                        options={Options?.documentOptions}
                                                        onChange={handleChange}
                                                        error={!!errors?.additionalChecks?.documentPaperwork && !values?.additionalChecks?.documentPaperwork}
                                                        helperText={errors?.additionalChecks?.documentPaperwork}
                                                        disabled={true}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Typography variant="p1">Inspector's Comments/Recommendations*</Typography>
                                            <TextInput
                                                label="Write comments here ..."
                                                name="inspectorComments"
                                                value={values.inspectorComments}
                                                onChange={handleChange}
                                                fullWidth
                                                multiline
                                                // minRows="4"
                                                rows={5}
                                                error={!!errors.inspectorComments && !values.inspectorComments}
                                                disabled={true}
                                            />
                                        </Stack>
                                    </Stack>

                                    {adData && adData?.inspection_details?.status === "pending" && (
                                        <Stack mt={4} flexDirection={"row"} justifyContent="flex-end" gap={'20px'}>
                                            <Button
                                                variant="containedReject"
                                                sx={{ width: '230px', textTransform: 'capitalize' }}
                                                // onClick={handleRejectAd}
                                                onClick={() => setModalOpen(true)}
                                            >
                                                Reject
                                            </Button>

                                            <Button
                                                type="submit"
                                                variant="containedPrimary"
                                                // color="primary"
                                                sx={{ width: '230px', textTransform: 'capitalize' }}
                                            >
                                                Approve
                                            </Button>
                                        </Stack>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                width={{ xs: '90%', sm: '50%', md: '40%', lg: '30%' }}
            >
                <Stack mt={'10%'}>
                    <TextInput
                        name={reason}
                        value={reason}
                        label="Write reason for rejection"
                        fullWidth
                        multiline
                        // minRows={4}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </Stack>
                <Stack mt={3} flexDirection="row" justifyContent="flex-end" gap="20px">
                    <Button
                        variant="outlinedCancel"
                        onClick={() => setModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="containedReject"
                        color="error"
                        sx={{ width: '200px' }}
                        onClick={handleRejectInspection}
                        disabled={!reason.trim()}
                    >
                        Submit
                    </Button>
                </Stack>
            </Modal>
        </>
    )
}

export default AdminInspectionForm
