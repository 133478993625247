import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const getMake = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.GET_MAKES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getModelByMakeId = async (makeId) => {
  try {
    const endpoint = API_ENDPOINTS.LISTING.GET_MODELS.replace(':makeId', makeId);
    const response = await axiosInstance.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTransmissionTypes = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.GET_TRANSMISSION_TYPES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBodyTypes = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.GET_BODY_TYPES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEngineTypes = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.GET_ENGINE_TYPES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFeatures = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.GET_FEATURES);
    return response.data;
  } catch (error) {
    throw error;
  }
};








