import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getUser } from '../api/services/userService';
import { useLoader } from './LoaderContext';
// Create the Auth Context
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const { showLoader, hideLoader } = useLoader();
  const [loadingAuth, setLoadingAuth] = useState(true);
  const navigate = useNavigate();

  // On component mount, check if token exists in localStorage
  useEffect(() => {
    const initializeAuth = async () => {
      showLoader();
      const storedToken = localStorage.getItem('authToken');
      if (storedToken) {
        setToken(storedToken);
        try {
          await fetchUserData(); // Wait for the user data to be fetched
        } finally {
          hideLoader(); // Ensure the loader is hidden after the data is fetched
          setLoadingAuth(false); 
        }
      } else {
        hideLoader(); // No token, stop the loader
        setLoadingAuth(false);
      }
    };
  
    initializeAuth();
  }, []);
  

  const fetchUserData = async () => {
    try {

      const response = await getUser();
      console.log('response user data get', response)
      setUser(response.data);
    } catch (e) {
      const { message } = e.response?.data || "An error occurred";
      console.error('Failed to fetch user:', e);
      toast.error(message);
    }
  };

  const signIn = (newToken, userData) => {
    localStorage.setItem('authToken', newToken);
    setToken(newToken);
    setUser(userData);

  };


  const logout = () => {
    localStorage.removeItem('authToken');
    setToken(null);
    setUser(null);
    navigate('/login');
  };

  const value = {
    loadingAuth,
    user,
    setUser,
    token,
    isAuthenticated: !!token,
    signIn,
    logout,
  };


  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
