export const API_ENDPOINTS = {
  USER: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/sign-up',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    GET_PROFILE: '/user',
    UPDATE_PROFILE: '/user',
    UPLOAD_PROFILE_IMAGE: '/user/upload',
  },
  LISTING: {
    GET_MAKES: '/listing/makes',
    GET_MODELS: '/listing/models/:makeId',
    GET_TRANSMISSION_TYPES: '/listing/transmission-types',
    GET_ENGINE_TYPES: '/listing/engine-types',
    GET_BODY_TYPES: '/listing/body-types',
    GET_FEATURES: '/listing/features',
  },

  CONSUMER: {
    CREATE_LISTING: '/listing/consumer',
    GET_LISTINGS: '/listing/consumer',
    GET_LISTING_BY_ID: '/listing/:listingId',
    UPDATE_LISTING: '/listing/consumer/:listingId',
    GET_LISTING_DETAILS: '/listing/consumer/:listingId',
    MAKE_LISTING_LIVE: '/listing/consumer/:listingId/go-live',
    UPDATE_RESERVED_PRICE: '/listing/consumer/:listingId/reserved-price',
    REPOST_LISTING: '/listing/consumer/:listingId/re-POST',
    DECODE_VIN: '/listing/consumer/decode-vin',
    ACCEPT_BID: '/listing/consumer/:listingId/bids/:bidId/accept',
    INSPECTION: '/listing/:listingId/inspection',
  },

  DEALER: {
    GET_ALL_AUCTIONS: '/listing/auctions',
    GET_LISTING_DETAILS: '/listing/dealer/:listingId',
    PLACE_BID: '/listing/dealer/bid/:listingId',
    GET_ALL_BIDS_LISTING: '/listing/dealer/bids',
  },

  ADMIN: {
    GET_USERS: '/admin/users',
    UPDATE_USER_STATUS: '/admin/users/userId/status',
    GET_ALL_LISTINGS: '/admin/listing/consumer',
    UPDATE_LISTING_STATUS: '/admin/listing/consumer/id/status',
    GET_INSPECTION_BY_ID: '/admin/listing/inspection/:inspectionId',
    UPDATE_INSPECTION_STATUS: '/admin/listing/inspection/:inspectionId/status'
  },

  NEGOTIATION: {
    NEGOTIATION_START: '/listing/:listingId/negotiation/start',
    COUNTER_NEGOTIATION: '/listing/:listingId/negotiation/counter',
    ACCEPT_OFFER: '/listing/:listingId/negotiation/accept',
    REJECT_OFFER: '/listing/:listingId/negotiation/reject'
  },

  UPLOADS: {
    UPLOAD_IMAGES: '/upload/fetch-file',
  },

  NOTIFICATION: {
    USER_NOTIFICATION: '/user/notifications',
  }
};
