import { Box, Container } from '@mui/material'
import React from 'react'

const NotFound = () => {
  return (
    <Container>
    <h1>
      Not Found
    </h1>
    </Container>
  )
}

export default NotFound
