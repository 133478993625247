import React from "react";
import {
  Card, Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Box, RadioGroup, FormControlLabel, Radio, Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "../../components/Checkbox";

const FilterSidebar = ({
  makeOptions = [],
  modelOptions = [],
  transmissionOptions = [],
  featuresOptions = [],
  priceRange = [],
  selectedMakes,
  setSelectedMakes,
  selectedModels,
  setSelectedModels,
  selectedTransmissions,
  setSelectedTransmissions,
  selectedFeatures,
  setSelectedFeatures,
  handlePriceChange,
}) => {
  return (
    <Card sx={{ backgroundColor: "#EDEEEF", width: "auto" }}>
      <Typography
        variant="p6"
        sx={{ fontWeight: "bold", mb: 2 }}
        backgroundColor="#22B14B"
        display="inline-block"
        width="100%"
        padding={2}
        textAlign={"center"}
      >
        Show Result By:
      </Typography>
      <Stack padding={2}>
        <Stack display={"flex"} gap={2}>
          <Accordion
            defaultExpanded
            sx={{ boxShadow: "none", borderRadius: "10px" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body5">Make</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Checkbox
                sx={{ width: '100%' }}
                options={makeOptions.map((option) => ({
                  label: `${option.label} (${option.count})`,
                  value: option.value,
                }))}
                selectedValues={selectedMakes}
                onChange={setSelectedMakes}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded sx={{ boxShadow: "none", borderRadius: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body5">Model</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Checkbox
                sx={{ width: '100%' }}
                options={modelOptions}
                selectedValues={selectedModels}
                onChange={setSelectedModels}
              />
            </AccordionDetails>
          </Accordion>
          <Stack>
            <Typography marginBottom={2} variant="body5">
              Transmission
            </Typography>
            <Stack
              borderRadius={2.5}
              backgroundColor="#ffffff"
              padding={"12px 10px"}
            >
              <RadioGroup
                value={selectedTransmissions}
                onChange={(e) => setSelectedTransmissions(e.target.value)}
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {transmissionOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </Stack>
          <Stack>
            <Typography marginBottom={2} variant="body5">
              Feature
            </Typography>
            <Stack
              borderRadius={2.5}
              backgroundColor="#ffffff"
              padding={"12px 10px"}
            >
              <Checkbox
                sx={{ width: '100%' }}
                options={featuresOptions}
                selectedValues={selectedFeatures}
                onChange={setSelectedFeatures}
              />
              {/* <Typography
                variant="p2"
                style={{ textDecorationLine: "underline" }}
                marginTop={2}
              >
                More Choices...
              </Typography> */}
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body5">Price Range</Typography>
            <Stack
              backgroundColor="white"
              borderRadius={2.5}
              padding={"12px 10px"}
              marginTop={2}
            >
              <Slider
                value={priceRange}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                min={1}
                max={100000}
                sx={{ color: "green", left: "10px", width: "90%" }}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                marginTop={2.5}
                padding={"0 20px"}
              >
                <Typography variant="body8">${priceRange[0]}</Typography>
                <Typography variant="body8">-</Typography>
                <Typography variant="body8">${priceRange[1]}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default FilterSidebar;

