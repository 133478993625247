import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";
import { Icon } from '@iconify/react';

const OwnershipVerificationSection = ({ values, setFieldValue, errors, touched, disabled = false }) => (
    <>
        <Typography variant="h4" mb={2} mt={2}>
            Ownership Verification
        </Typography>

        <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
                <FileUpload
                    name="driversLicense"
                    placeholder="Upload Driver's License"
                    label="Driver's License*"
                    maxImages={1}
                    value={values.driversLicense}
                    setFieldValue={setFieldValue}
                    error={errors.driversLicense && touched.driversLicense}
                    helperText={errors.driversLicense && touched.driversLicense && values.driversLicense.length === 0 ? errors.driversLicense : ''}
                    disabled={disabled}
                // error={errors.driversLicense && touched.driversLicense}
                // helperText={errors.driversLicense && touched.driversLicense ? errors.driversLicense : "Please upload a valid driver's license."}
                />
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <FileUpload
                    name="vehicleRegistration"
                    placeholder="Upload Vehicle Registration"
                    label="Vehicle Registration*"
                    maxImages={1}
                    value={values.vehicleRegistration}
                    setFieldValue={setFieldValue}
                    error={errors.vehicleRegistration && touched.vehicleRegistration}
                    helperText={errors.vehicleRegistration && touched.vehicleRegistration && values.vehicleRegistration.length === 0 ? errors.vehicleRegistration : ''}
                    disabled={disabled}
                // error={errors.vehicleRegistration && touched.vehicleRegistration}
                // helperText={errors.vehicleRegistration && touched.vehicleRegistration ? errors.vehicleRegistration : "Please upload a valid vehicle registration."}
                />
            </Grid>
        </Grid>
    </>
);

export default OwnershipVerificationSection;