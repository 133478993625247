// MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Footer, PrivateNavbar, PublicNavbar } from '../components';
import { NotificationProvider } from '../context/notificationContext';

const MainLayout = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            {isAuthenticated &&
                <NotificationProvider>
                    <PrivateNavbar />
                </NotificationProvider>
            }
            <Outlet />
            <Footer />
        </>
    );
};

export default MainLayout;
