import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchUserNotification, markUserNotificationasRead } from "../api/services/notificationService";
import { useLocation } from "react-router-dom";
import socket from "../config/socket";
import { useAuth } from "./AuthContext";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const pageSize = 5;
    const { user } = useAuth()

    // Fetch notifications via API
    const fetchNotifications = async (page = 1, append = false) => {
        try {
            setLoading(true);
            const data = await fetchUserNotification(page, pageSize, false);
            console.log("Fetched notifications:", data?.data);

            if (append) {
                setNotifications((prevNotifications) => [...prevNotifications, ...(data?.data || [])]);
            } else {
                setNotifications(data?.data || []);
            }

            const unread = data?.data?.filter((notif) => notif.status !== "read").length || 0;
            setUnreadCount(unread);

            setHasMore(data?.data?.length === pageSize);
        } catch (error) {
            console.error("Error fetching notifications:", error);
            setHasMore(false); // Assume no more data if there's an error
        } finally {
            setLoading(false);
        }
    };

    // Mark all notifications as read
    const fetchAndMarkNotificationsAsRead = async () => {
        try {
            await markUserNotificationasRead(1, currentPage * pageSize, true);
            setUnreadCount(0);
        } catch (error) {
            console.error("Error marking notifications as read:", error);
        }
    };

    // WebSocket: Listen for new notifications
    useEffect(() => {
        const userId = user.id;

        if (userId) {
            // Join notifications room
            console.log(socket.emit("join_notifications", { userId }));

            // Listen for new notifications
            socket.on("new_notification", (newNotification) => {
                console.log("New notification received:", newNotification);

                // Update state with the new notification
                setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);

                // Increment unread count
                setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
            });
        }

        // Cleanup listener on unmount
        return () => {
            socket.off("new_notification");
        };
    }, [user.id]);


    // useEffect(() => {
    //     fetchNotifications();
    // }, [useLocation()]);

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                setNotifications,
                unreadCount,
                loading,
                currentPage,
                setCurrentPage,
                fetchNotifications,
                fetchAndMarkNotificationsAsRead,
                hasMore,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
