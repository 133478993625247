import * as React from "react";
import { Formik } from 'formik';
import Options from './data.json'
import toast from 'react-hot-toast';
import InfoIcon from "@mui/icons-material/Info";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { viewAd, submitInspection } from "../../../api/services/consumerService";
import { Stack, Box, Typography, Container, Grid2 as Grid } from "@mui/material";
import { Button, TextInput, Checkbox, Toaster, SelectInput, RadioInput, DateTimePicker, FileUpload } from "../../../components";
import { getMake, getModelByMakeId, getTransmissionTypes, getBodyTypes, getEngineTypes, getFeatures } from "../../../api/services/listingService";
import validationSchema from "./ValidationSchema";
import dayjs from 'dayjs';
import ExteriorConditionSection from "../../../components/Form/ExteriorConditionSection";
import InteriorConditionSection from "../../../components/Form/InteriorConditionSection";
import MechanicalConditionSection from "../../../components/Form/MechanicalConditionSection";
import SafetyFeatureSection from "../../../components/Form/SafetyFeatureSection";

const InspectionForm = () => {
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelptions] = useState([]);
    const [adData, setAdData] = useState(null);
    // const location = useLocation();
    const navigate = useNavigate();
    const { listingId } = useParams();

    const handleBackClick = () => {
        navigate(-1);
    };

    const initialValues = adData ? {
        modelYear: adData?.vehicle_details?.modelYear || '',
        make: adData?.makes?.id || '',
        model: adData?.models?.id || '',
        mileage: adData?.vehicle_details?.mileage || '',
        // licensePlate: adData?.lisencePlate || '',
        driversLicense: adData?.driversLicense ? [adData?.driversLicense] : [],
        inspectorName: adData?.inspectorName || '',
        inspectionDate: adData?.inspectionDate ? dayjs(adData.inspectionDate) : dayjs(),
        exteriorCondition: {
            bodyPanel: adData?.exteriorCondition?.bodyPanel || '',
            windowsGlass: adData?.exteriorCondition?.windowsGlass || '',
            lights: adData?.exteriorCondition?.lights || '',
            mirrors: adData?.exteriorCondition?.mirrors || '',
            tires: adData?.exteriorCondition?.tires || '',
            bumpers: adData?.exteriorCondition?.bumpers || ''
        },
        interiorCondition: {
            upholsterySeats: adData?.interiorCondition?.upholsterySeats || '',
            dashboard: adData?.interiorCondition?.dashboard || '',
            carpetMats: adData?.interiorCondition?.carpetMats || '',
            headliner: adData?.interiorCondition?.headliner || '',
            airconditioningHeating: adData?.interiorCondition?.airconditioningHeating || '',
            entertainmentSystem: adData?.interiorCondition?.entertainmentSystem || ''
        },
        mechanicalCondition: {
            engine: adData?.mechanicalCondition?.engine || '',
            transmission: adData?.mechanicalCondition?.transmission || '',
            brakes: adData?.mechanicalCondition?.brakes || '',
            steering: adData?.mechanicalCondition?.steering || '',
            suspension: adData?.mechanicalCondition?.suspension || '',
            exhaustSystem: adData?.mechanicalCondition?.exhaustSystem || '',
            battery: adData?.mechanicalCondition?.battery || ''
        },
        safetyFeatures: {
            airbags: adData?.safetyFeatures?.airbags || '',
            seatBelts: adData?.safetyFeatures?.seatBelts || ''
        },
        additionalChecks: { documentPaperwork: adData?.additionalChecks?.documentPaperwork || '' },
        inspectorComments: adData.inspectorComments || '',
    } : {
        modelYear: '', make: '', model: '', mileage: '', licensePlate: '', inspectorName: '', inspectionDate: dayjs(), inspectorComments: '', driversLicense: [],
        exteriorCondition: { bodyPanel: '', windowsGlass: '', lights: '', mirrors: '', tires: '', bumpers: '' },
        interiorCondition: { upholsterySeats: '', dashboard: '', carpetMats: '', headliner: '', airconditioningHeating: '', entertainmentSystem: '' },
        mechanicalCondition: { engine: '', transmission: '', brakes: '', steering: '', suspension: '', exhaustSystem: '', battery: '' },
        safetyFeatures: { airbags: '', seatBelts: '' }, additionalChecks: { documentPaperwork: '' }
    };

    // console.log('initialValues', initialValues)

    const handleInspectionForm = async (values) => {
        console.log("Values", values);

        const inspectionData = {
            inspectorName: values.inspectorName,
            inspectionDate: new Date(
                values.inspectionDate.$y,
                values.inspectionDate.$M,
                values.inspectionDate.$D,
                values.inspectionDate.$H,
                values.inspectionDate.$m,
                values.inspectionDate.$s
            ).toISOString(),
            exteriorCondition: {
                bodyPanels: values.exteriorCondition.bodyPanel,
                windowGlass: values.exteriorCondition.windowsGlass,
                lights: values.exteriorCondition.lights,
                mirrors: values.exteriorCondition.mirrors,
                tires: values.exteriorCondition.tires,
                bumpers: values.exteriorCondition.bumpers,
            },
            interiorCondition: {
                upholstery: values.interiorCondition.upholsterySeats,
                seats: values.interiorCondition.upholsterySeats,
                dashboard: values.interiorCondition.dashboard,
                carpetsMats: values.interiorCondition.carpetMats,
                headliner: values.interiorCondition.headliner,
                airConditioningHeating: values.interiorCondition.airconditioningHeating,
                entertainmentSystem: values.interiorCondition.entertainmentSystem,
            },
            mechanicalCondition: {
                engine: values.mechanicalCondition.engine,
                transmission: values.mechanicalCondition.transmission,
                brakes: values.mechanicalCondition.brakes,
                steering: values.mechanicalCondition.steering,
                suspension: values.mechanicalCondition.suspension,
                exhaustSystem: values.mechanicalCondition.exhaustSystem,
                battery: values.mechanicalCondition.battery,
            },
            safetyFeatures: {
                airbags: values.safetyFeatures.airbags,
                seatbelts: values.safetyFeatures.seatBelts,
            },
            additionalChecks: {
                documents: values.additionalChecks.documentPaperwork,
            },
            comments: values.inspectorComments,
        };

        await Toaster.handleApiCall(() => submitInspection(listingId, inspectionData),
            "Form submitted successfully.",
            (response) => {
                navigate('/ads')
            }
        );
    };

    const fetchModel = async (makeId) => {
        try {
            console.log("makeId", makeId)
            const data = await getModelByMakeId(makeId);
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setModelptions(options);
        } catch (error) {
            console.error("Error fetching model data:", error);
        }
    };

    const fetchOptions = async (fetchFunction, setOptions, errorMsg) => {
        try {
            const data = await fetchFunction();
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setOptions(options);
        } catch (error) {
            console.error(`Error fetching ${errorMsg} data:`, error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await viewAd(listingId);
            console.log("ViewAd API", response.data);
            const data = response?.data || {};
            setAdData(data);

            if (data.makes?.id) {
                fetchModel(data.makes.id);
            }
        } catch (error) {
            console.error("Error fetching ad details:", error);
        }
    };

    const modelYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1990;
        const options = [];

        for (let year = currentYear; year >= startYear; year--) {
            options.push({ value: year, label: year.toString() });
        }

        return options;
    }

    useEffect(() => {
        if (listingId) {
            fetchData();
        }
    }, [listingId])

    useEffect(() => {
        fetchOptions(getMake, setMakeOptions, "make");
    }, []);

    return (
        <>
            <Container >
                <Grid container sx={{ width: "100%" }}>
                    <Grid size={{ xs: 12 }} sx={{ width: "100%" }}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            // validateOnMount={false}
                            onSubmit={handleInspectionForm}
                        >

                            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors, isValid, dirty }) => (
                                <form onSubmit={handleSubmit} autoComplete="off" autoCapitalize="off">
                                    <Stack sx={{ boxShadow: "0px 1px 7px 0px #D1D1D1", borderTop: "3px solid #22B14B", padding: { xs: "10px", md: "40px" } }}>
                                        <Stack
                                            spacing={2}
                                            sx={{ marginTop: { xs: "30px" } }}
                                        >

                                            <Typography variant="h4" mb={2} mt={2}>
                                                Inspection Form
                                            </Typography>

                                            <Grid container spacing={2}>
                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Inspector Name*</Typography>
                                                        <TextInput
                                                            label="Please Enter"
                                                            name="inspectorName"
                                                            value={values.inspectorName}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            error={!!errors.inspectorName && !values.inspectorName}
                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Inspection Date*</Typography>
                                                        <DateTimePicker
                                                            label="Inspection Date"
                                                            name="inspectionDate"
                                                            // value={values.inspectionDate}
                                                            value={values.inspectionDate || dayjs()}
                                                            onChange={setFieldValue}
                                                            error={touched.inspectionDate && !!errors.inspectionDate}

                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Typography variant="body2" fontSize={20} fontWeight={700} mb={2} mt={2}>
                                                Vehicle Information
                                            </Typography>

                                            <Grid container spacing={2}>
                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Make*</Typography>
                                                        <SelectInput
                                                            name="make"
                                                            label="Please Select"
                                                            value={values.make}
                                                            // setFieldValue={(field, selectedMake) => {
                                                            //   setFieldValue("make", selectedMake);
                                                            //   setFieldValue("model", "");
                                                            //   fetchModel(selectedMake.value);
                                                            // }}
                                                            onChange={(event) => {
                                                                const selectedMake = event.target.value;
                                                                setFieldValue("make", selectedMake);
                                                                fetchModel(selectedMake);
                                                            }}
                                                            options={makeOptions}
                                                            error={!!errors.make && !values.make}
                                                            disabled={true}

                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Model*</Typography>
                                                        <SelectInput
                                                            name="model"
                                                            label="Please Select"
                                                            value={values.model}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            disabled={!values.make || true}
                                                            options={modelOptions}
                                                            error={!!errors.model && !values.model}

                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Model Year*</Typography>
                                                        <SelectInput
                                                            name="modelYear"
                                                            label="Please Select"
                                                            value={values.modelYear}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            options={modelYearOptions()}
                                                            error={!!errors.modelYear && !values.modelYear}
                                                            disabled={true}

                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={1} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Mileage*</Typography>
                                                        <TextInput
                                                            label="Please Enter"
                                                            name="mileage"
                                                            value={values.mileage}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            // error={!!errors.mileage && !values.mileage}
                                                            error={Boolean(errors.mileage)}
                                                            helperText={errors.mileage &&
                                                                (errors.mileage !== "Mileage is required" ? errors.mileage : '')
                                                            }
                                                            disabled={true}

                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid size={{ xs: 12, md: 6 }}>
                                                <FileUpload
                                                    name="driversLicense"
                                                    placeholder="Upload Driver's License"
                                                    label="Driver's License*"
                                                    maxImages={1}
                                                    value={values.driversLicense}
                                                    setFieldValue={setFieldValue}
                                                    error={errors.driversLicense && touched.driversLicense}
                                                    helperText={errors.driversLicense && touched.driversLicense && values.driversLicense.length === 0 ? errors.driversLicense : ''}
                                                    disabled={true}
                                                // error={errors.driversLicense && touched.driversLicense}
                                                // helperText={errors.driversLicense && touched.driversLicense ? errors.driversLicense : "Please upload a valid driver's license."}
                                                />
                                            </Grid>

                                            <ExteriorConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                            />

                                            <InteriorConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                            />

                                            <MechanicalConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                            />

                                            <SafetyFeatureSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                            />

                                            <Typography variant="p7" color="primary.main" fontWeight={600}>Additional Checks</Typography>

                                            <Grid size={{ xs: 12, md: 12 }}>
                                                <Stack spacing={1} sx={{ width: "100%" }}>
                                                    <Typography variant="p1">Documents & Paperwork*</Typography>
                                                    <RadioInput
                                                        name="additionalChecks.documentPaperwork"
                                                        value={values.additionalChecks?.documentPaperwork}
                                                        options={Options?.documentOptions}
                                                        onChange={handleChange}
                                                        error={!!errors?.additionalChecks?.documentPaperwork && !values?.additionalChecks?.documentPaperwork}
                                                        helperText={errors?.additionalChecks?.documentPaperwork}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Typography variant="p1">Inspector's Comments/Recommendations*</Typography>
                                            <TextInput
                                                label="Write comments here ..."
                                                name="inspectorComments"
                                                value={values.inspectorComments}
                                                onChange={handleChange}
                                                fullWidth
                                                multiline
                                                // minRows="4"
                                                rows={5}
                                                error={!!errors.inspectorComments && !values.inspectorComments}

                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack mt={4} flexDirection={"row"} justifyContent="flex-end" gap={'20px'}>
                                        <Button
                                            variant="outlined"
                                            color="transparent"
                                            sx={{ width: '140px' }}
                                            onClick={handleBackClick}
                                        >
                                            Back
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            // fullWidth
                                            // disabled={!(isValid && dirty)}
                                            sx={{ width: '230px', textTransform: 'capitalize' }}
                                            onClick={(e) => {
                                                if (!isValid) {
                                                    toast.dismiss();
                                                    toast.error('Please fill out all required fields');
                                                }
                                            }}
                                        >
                                            Submit Inspection Form
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default InspectionForm
