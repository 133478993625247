export const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US').format(new Date(date));
};


export const formatTime = (milliseconds) => {
    if (milliseconds <= 0) {
        return false;
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let timeString = "";
    if (days > 0) {
        timeString += `${days}d `;
    }
    // timeString += `${hours}:${minutes}:${seconds}s`;

    // const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    timeString += `${hours}:${formattedMinutes}:${formattedSeconds}s`;

    return timeString;
};

//   const formatTime = (milliseconds) => {
//     const totalSeconds = Math.floor(milliseconds / 1000);
//     const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0');
//     const seconds = String(totalSeconds % 60).padStart(2, '0');
//     return `${minutes}:${seconds}`;
//   };