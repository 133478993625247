import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";
import { Icon } from '@iconify/react';

const VehicleConditionSection = ({ values, errors, touched, handleChange, setFieldValue, Options, disabled = false  }) => {
    return(
    <>
        <Stack spacing={1} sx={{ width: "100%" }}>
            <Grid size={{ xs: 12, md: 12 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Vehicle Condition*</Typography>
                    <RadioInput
                        name="accidentHistory.count"
                        label="Accident History: Any previous accidents or damages?"
                        value={values.accidentHistory?.count}
                        options={Options?.vehicleConditionOptions}
                        onChange={handleChange}
                        // error={!!errors.accidentHistory && !values.accidentHistory}
                        // helperText={errors.accidentHistory}
                        error={!!errors.accidentHistory?.count && !values.accidentHistory?.count}
                        helperText={errors.accidentHistory?.count}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            {(values.accidentHistory?.count === '1' || values.accidentHistory?.count === '2') && (
                <Grid size={{ xs: 12, md: 12 }}>
                    <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Stack spacing={1} sx={{ width: "100%" }}>
                                <Typography variant="p1">Please select the accident type</Typography>
                                <RadioInput
                                    name="accidentHistory.severity"
                                    // label="Please select the accident type"
                                    value={values.accidentHistory?.severity}
                                    options={Options?.accidentTypeOptions}
                                    onChange={handleChange}
                                    error={!!errors.accidentHistory?.severity && !values.accidentHistory?.severity}
                                    helperText={errors.accidentHistory?.severity}
                                    disabled={disabled}
                                />
                            </Stack>
                        </Grid>
                    </MuiCard>
                </Grid>
            )}
        </Stack>

        <Stack spacing={1} sx={{ width: "100%" }}>
            <Grid size={{ xs: 12, md: 12 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Exterior Damages*</Typography>
                    <RadioInput
                        name="exteriorDamagesStatus"
                        value={values.exteriorDamagesStatus}
                        options={Options?.exteriorDamagesOptions}
                        onChange={handleChange}
                        error={!!errors.exteriorDamagesStatus && !values.exteriorDamagesStatus}
                        helperText={errors.exteriorDamagesStatus}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            {values.exteriorDamagesStatus === 'yes' && (
                <Grid size={{ xs: 12, md: 12 }}>
                    <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                        {/* <MuiCard sx={{ padding: '15px' }} > */}
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Stack spacing={1} sx={{ width: "100%" }}>
                                <Typography variant="p1">Please select all applicable damages</Typography>
                                {/* <Box> */}
                                <Checkbox
                                    sx={{ width: { xs: '100%', md: '100%' } }}
                                    options={Options.exteriorDamagesList}
                                    selectedValues={values.exteriorDamage}
                                    onChange={(newValues) => setFieldValue("exteriorDamage", newValues)}
                                    error={errors.exteriorDamage && touched.exteriorDamage}
                                    helperText={errors.exteriorDamage && touched.exteriorDamage && values.exteriorDamage.length === 0 ? errors.exteriorDamage : ''}
                                    disabled={disabled}
                                />
                                {/* </Box> */}
                            </Stack>
                        </Grid>
                    </MuiCard>
                </Grid>
            )}
        </Stack>


        <Stack spacing={1} sx={{ width: "100%" }}>
            <Grid size={{ xs: 12, md: 12 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Interior Damages*</Typography>
                    <RadioInput
                        name="interiorDamagesStatus"
                        // label="Accident History: Any previous accidents or damages?" 
                        value={values.interiorDamagesStatus}
                        options={Options?.interiorDamagesOptions}
                        onChange={handleChange}
                        error={!!errors.interiorDamagesStatus && !values.interiorDamagesStatus}
                        helperText={errors.interiorDamagesStatus}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            {values.interiorDamagesStatus === 'yes' && (
                <Grid size={{ xs: 12, md: 12 }}>
                    <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                        {/* <MuiCard sx={{ padding: '15px' }} > */}
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Stack spacing={1} sx={{ width: "100%" }}>
                                <Typography variant="p1">Please select all applicable damages</Typography>
                                {/* <Box> */}
                                <Checkbox
                                    sx={{ width: { xs: '100%', md: '100%' } }}
                                    options={Options?.interiorDamagesList}
                                    selectedValues={values.interiorDamage}
                                    onChange={(newValues) => setFieldValue("interiorDamage", newValues)}
                                    error={errors.interiorDamage && touched.interiorDamage}
                                    helperText={errors.interiorDamage && touched.interiorDamage && values.interiorDamage.length === 0 ? errors.interiorDamage : ''}
                                    disabled={disabled}
                                />
                                {/* </Box> */}
                            </Stack>
                        </Grid>
                    </MuiCard>
                </Grid>
            )}
        </Stack>

        <Stack spacing={1} sx={{ width: "100%" }}>
            <Grid size={{ xs: 12, md: 12 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Mechanical Issues*</Typography>
                    <RadioInput
                        name="mechanicalIssuesStatus"
                        // label="Accident History: Any previous accidents or damages?" 
                        value={values.mechanicalIssuesStatus}
                        options={Options?.mechanicalIssuesOptions}
                        onChange={handleChange}
                        error={!!errors.mechanicalIssuesStatus && !values.mechanicalIssuesStatus}
                        helperText={errors.mechanicalIssuesStatus}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            {values.mechanicalIssuesStatus === 'yes' && (
                <Grid size={{ xs: 12, md: 12 }}>
                    <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                        {/* <MuiCard sx={{ padding: '15px' }} > */}
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Stack spacing={1} sx={{ width: "100%" }}>
                                <Typography variant="p1">Please select all mechanical damages</Typography>
                                {/* <Box> */}
                                <Checkbox
                                    sx={{ width: { xs: '100%', md: '100%' } }}
                                    options={Options?.mechanicalIssuesList}
                                    selectedValues={values.mechanicalIssues}
                                    onChange={(newValues) => setFieldValue("mechanicalIssues", newValues)}
                                    error={errors.mechanicalIssues && touched.mechanicalIssues}
                                    helperText={errors.mechanicalIssues && touched.mechanicalIssues && values.mechanicalIssues.length === 0 ? errors.mechanicalIssues : ''}
                                    disabled={disabled}
                                />
                                {/* </Box> */}
                            </Stack>
                        </Grid>
                    </MuiCard>
                </Grid>
            )}
        </Stack>


        <Stack spacing={1} sx={{ width: "100%" }}>
            <Grid size={{ xs: 12, md: 12 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Modification or Aftermarket Parts*</Typography>
                    <RadioInput
                        name="modificationStatus"
                        // label="Accident History: Any previous accidents or damages?" 
                        value={values.modificationStatus}
                        options={Options?.modificationOptions}
                        onChange={handleChange}
                        error={!!errors.modificationStatus && !values.modificationStatus}
                        helperText={errors.modificationStatus}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            {values.modificationStatus === 'yes' && (
                <TextInput
                    label="Please enter modification details"
                    name="modification"
                    value={values.modification}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    // minRows="4"
                    rows={5}
                    error={!!errors.modification && !values.modification}
                    disabled={disabled}
                />
            )}
        </Stack>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">How many key fobs do you have*</Typography>
                <RadioInput
                    name="keyFobs"
                    // label="Accident History: Any previous accidents or damages?" 
                    value={values.keyFobs}
                    options={Options?.keyFobsOptions}
                    onChange={handleChange}
                    // error={!!errors.keyFobs && !values.keyFobs}
                    error={!!errors.keyFobs && !values.keyFobs}
                    helperText={errors.keyFobs}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Tire Condition*</Typography>
                <RadioInput
                    name="tireCondition"
                    // label="Accident History: Any previous accidents or damages?" 
                    value={values.tireCondition}
                    options={Options?.tireConditionOptions}
                    onChange={handleChange}
                    error={!!errors.tireCondition && !values.tireCondition}
                    helperText={errors.tireCondition}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Condition Rating*</Typography>
                <RadioInput
                    name="conditionRating"
                    // label="Accident History: Any previous accidents or damages?" 
                    value={values.conditionRating}
                    options={Options?.conditionRatingOptions}
                    onChange={handleChange}
                    error={!!errors?.conditionRating && !values?.conditionRating}
                    helperText={errors?.conditionRating}
                    disabled={disabled}
                />
            </Stack>
        </Grid>
    </>
    )
};

export default VehicleConditionSection;
