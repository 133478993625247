import { Icon } from "@iconify/react";
import BidTrackerCard from "./BidTrackerCard";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { consumerAd } from "../../../api/services/consumerService";
import ImageCarousel from "../../../components/ImageCarousel";
import { Box, Container, Stack, Typography, Grid2 as Grid, } from "@mui/material";

const ViewAd = () => {
  const { id } = useParams();
  const [adData, setAdData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await consumerAd(id);
      console.log("consumerAd api response", response.data);
      const data = response?.data || [];
      setAdData(data);
    } catch (error) {
      console.error("Error fetching car details:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const style = {
    py: 0,
    width: "100%",
    maxWidth: 300,
    border: "1px solid #22B14B",
    margin: "24px auto",
  };

  const featureIcons = {
    "Air Conditioning": "material-symbols:ac-unit",
    "Cruise Control": "mdi:car-cruise-control",
    "Bluetooth Connectivity": "mdi:bluetooth",
    "Rear Camera": "mdi:camera-rear",
    "Navigation System": "mdi:navigation-outline",
    "Sunroof": "mdi:car-select",
    "Power Windows": "mdi:car-door",
    "ABS (Anti-lock Braking System)": "mingcute:abs-fill",
    "Airbags": "mdi:airbag",
    "Automatic Transmission": "mdi:car-cruise-control",
    "LED Headlights": "mdi:lightbulb",
    "Remote Keyless Entry": "mdi:lock-open-outline",
    "Push Button Start": "mdi:power",
    "Parking Sensors": "mdi:car-parking-lights",
    "Alloy Wheels": "mdi:car-tire-alert",
    "Traction Control": "mdi:car-traction-control",
    "Voice Recognition": "mdi:microphone",
    "Wireless Charging": "mdi:wireless",
    "Apple CarPlay/Android Auto": "mdi:apple-safari",
    "Heated Seats": "ph:seat-fill",
  };

  const transformedFeatures =
    adData?.features?.map((feature) => ({
      ...feature,
      icon: featureIcons[feature?.name] || "mdi:help",
      label: feature.name,
      key: feature.id,
    })) || [];

  const specificationIcons = {
    engineCapacity: "mdi:engine",
    mileage: "mdi:road",
    fuel: "mdi:gas-station",
    drive: "solar:wheel-bold",
    key: "mdi:key-variant",
    transmission: "mdi:car-shift-pattern",
  };

  const transformedSpecifications = [
    {
      key: "engineCapacity",
      label: `${adData?.vehicle_details?.engineCapacity} CC`,
      icon: specificationIcons.engineCapacity,
    },
    {
      key: "mileage",
      label: `${adData?.vehicle_details?.mileage} MI`,
      icon: specificationIcons.mileage,
    },
    {
      key: "fuel",
      label: adData?.engine_types?.name,
      icon: specificationIcons.fuel,
    },
    {
      key: "transmission",
      label: adData?.transmission_types?.name,
      icon: specificationIcons.transmission,
    },
    {
      key: "key",
      // label: adData?.vehicle_details?.keyFobs,
      label: adData?.vehicle_details?.keyFobs > 1 ? "2+" : adData?.vehicle_details?.keyFobs,
      icon: specificationIcons.key,
    },
  ];

  const renderDetails = (details) => {
    return details.map((detail, index) => (
      <Typography
        key={index}
        display="flex"
        justifyContent="space-between"
        padding="20px 0 15px"
        borderBottom="1px solid #EBEBEB"
        variant="p4"
      >
        {detail.label}
        <Typography variant="p3">{detail.value}</Typography>
      </Typography>
    ));
  };

  const details = [
    { label: "Make", value: adData?.makes?.name },
    { label: "Transmission", value: adData?.transmission_types?.name },
    { label: "Model Year", value: adData?.vehicle_details?.modelYear },
    { label: "Seat Capacity", value: adData?.vehicle_details?.seatCapacity },
    { label: "Engine Capacity", value: adData?.vehicle_details?.engineCapacity },
  ];

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          bgcolor="#fff"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          padding={{ xs: "1rem 0.5rem", md: 4 }}
          width="100%"
          marginTop={4}
          marginBottom={5}
        >
          <Grid size={{ xs: 12, md: 7 }} sx={{ margin: { xs: "0", md: "0" } }}>
            <Stack>
              <ImageCarousel images={adData?.images} />
            </Stack>
            <Stack paddingTop="36px">

              <Grid container spacing={2} marginBottom={2} justifyContent="space-between">
                {transformedSpecifications.map((spec) => (
                  <Grid xs={6} md={2} key={spec.key}>
                    <Stack display="flex" alignItems="center" gap="10px">
                      <Box
                        display="flex" flexDirection="column" alignItems="center" padding="15px" border="1px solid #EBEBEB" borderRadius="10px">
                        <Icon
                          icon={spec.icon}
                          style={{ fontSize: "24px", color: "#333" }}
                        />
                      </Box>
                      <Typography variant="body2">{spec.label}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>

              <Grid container spacing={2} display="block">
                <Grid xs={6}>{renderDetails(details)}</Grid>
              </Grid>

              {adData && (
                <Grid margin="20px 0">
                  <Typography variant="h6" fontWeight="bold" marginBottom={1}>
                    Features
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" gap={2}>
                    {transformedFeatures.map((feature) => (
                      <Stack
                        key={feature.key}
                        display="flex" direction="row" alignItems="center" gap={1.6} width="48%">
                        <Stack
                          display="flex" flexDirection="column" alignItems="center" padding="15px" border="1px solid #EBEBEB" borderRadius="10px">
                          <Icon
                            icon={feature.icon}
                            style={{ fontSize: "24px" }}
                          />
                        </Stack>
                        <Typography variant="body2">{feature.label}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
              )}

              <Typography variant="h6" fontWeight="bold" marginBottom={1}>
                Other Details
              </Typography>
              <Grid container spacing={2} display="block">
                <Grid xs={6}>

                  {(adData?.vehicle_details?.loanOrLeaseStatus === "loan" || adData?.vehicle_details?.loanOrLeaseStatus === "lease") && (
                    <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4" textTransform="capitalize">
                      {adData?.vehicle_details?.loanOrLeaseStatus}
                      <Stack spacing={0.5}>
                        <Typography variant="p3">
                          {adData?.vehicle_details?.companyName}
                        </Typography>
                        <Typography variant="p3" color="primary.main" alignSelf="flex-end">
                          {adData?.vehicle_details?.dueAmount}
                        </Typography>
                      </Stack>
                    </Typography>
                  )}


                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    Accident History
                    <Stack spacing={0.5}>
                      <Typography variant="p3">
                        {/* {adData?.vehicle_details?.accidentHistory?.count} Accident */}
                        {adData?.vehicle_details?.accidentHistory?.count}
                        {adData?.vehicle_details?.accidentHistory?.count > 1 && '+'} Accident
                      </Typography>
                      <Typography variant="p3" color="primary.main" alignSelf="flex-end">
                        {adData?.vehicle_details?.accidentHistory?.severity}
                      </Typography>
                    </Stack>
                  </Typography>


                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    Exterior Damages
                    <Typography variant="p3" sx={{ ml: { xs: 4, sm: 0 } }} >
                      {adData?.vehicle_details?.exteriorDamage?.join(" | ") || "No Damages"}
                    </Typography>
                  </Typography>
                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    Interior Damages
                    <Typography variant="p3" sx={{ ml: { xs: 4, sm: 0 } }}>
                      {adData?.vehicle_details?.interiorDamage?.join(" | ") || "No Damages"}
                    </Typography>
                  </Typography>
                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    Mechanical Issues
                    <Typography variant="p3" sx={{ ml: { xs: 2, sm: 0 } }}>
                      {adData?.vehicle_details?.mechanicalIssues?.join(" | ") || "No Issues"}
                    </Typography>
                  </Typography>
                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    Tire Condition
                    <Typography variant="p3">
                      {adData?.vehicle_details?.tireCondition}
                    </Typography>
                  </Typography>
                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    Condition Rating
                    <Typography variant="p3">
                      {adData?.vehicle_details?.condition}
                    </Typography>
                  </Typography>
                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    <Stack>
                      Modifications or Aftermarket Parts
                      <Typography variant="p3">
                        {adData?.vehicle_details?.modifications}
                      </Typography>
                    </Stack>
                  </Typography>
                  <Typography display="flex" justifyContent="space-between" padding="20px 0 15px" borderBottom="1px solid #EBEBEB" variant="p4">
                    <Stack>
                      Description
                      <Typography variant="p3">
                        {adData?.description}
                      </Typography>
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>

            </Stack>
          </Grid>

          <Grid
            size={{ xs: 12, md: 5 }}
            sx={{ margin: { xs: "0 0.2rem", md: "0" } }}
          >
            <BidTrackerCard data={adData} refreshData={fetchData} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ViewAd;
