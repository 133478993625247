import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const startNegotiation = async (listingId, bidId, offerAmount, dealerId) => {
    try {
        const endpoint = API_ENDPOINTS.NEGOTIATION.NEGOTIATION_START.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint, {
            bidId,
            offerAmount,
            dealerId,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const counterNegotiation = async (listingId, negotiationId, offerAmount) => {
    try {
        const endpoint = API_ENDPOINTS.NEGOTIATION.COUNTER_NEGOTIATION.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint, {
            negotiationId,
            offerAmount,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const acceptNegotiation = async (listingId, negotiationId ) => {
    try {
        const endpoint = API_ENDPOINTS.NEGOTIATION.ACCEPT_OFFER.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint, {
            negotiationId,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const rejectNegotiation = async (listingId, negotiationId ) => {
    try {
        const endpoint = API_ENDPOINTS.NEGOTIATION.REJECT_OFFER.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint, {
            negotiationId,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};