import React from "react";
import { Typography, Grid2 as Grid } from "@mui/material";
import { ImageListItem, Button, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import Stack from "@mui/material/Stack";

const Footer = () => {
  const pages = ["Home", "About", "Contact Us", "Privacy Policy"];

  return (
    <Grid sx={{ backgroundColor: "black", padding: { xs: '40px 32px', md: "72px 160px" } }} marginTop="40px">
      <Grid
        container
        sx={{
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
          marginBottom: "80px",
          padding: { xs: '24px 0', md: '0' }
        }}
      >
        <Grid size={{ xs: 12, sm: 6 }}>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: { xs: "start" },
            }}
          >
            {pages.map((page) => (
              <Button
                variant="body1"
                key={page}
                sx={{
                  fontFamily: 'Nunito',
                  color: '#ffffff',
                  display: { xs: 'block', md: 'flex' },
                  padding: { xs: '0', md: '12px ' },
                  lineHeight: '3', height: 'auto',
                  textAlign: 'left'
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}
          sx={{
            display: "flex",
            justifyContent: { xs: "start", md: 'end' },
            alignItems: 'center',
            gap: '30px',
            marginTop: { xs: '20px', md: '0' }
          }}
        >
          <Icon icon="mdi:twitter" style={{ color: 'white', fontSize: '20px' }} />
          <Icon icon="ri:linkedin-fill" style={{ color: 'white', fontSize: '20px' }} />
          <Icon icon="ri:facebook-fill" style={{ color: 'white', fontSize: '20px' }} />
        </Grid>
      </Grid>
      <Grid>
        <Grid container>
          <Grid size={{ xs: 12 }}>
            <Box>
              <Typography variant="footer">MAKE A BID WITH US!</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '60px' }}>
          <Grid size={{ xs: 12, md: 6 }} gap={'10px'} display={'flex'} flexDirection={{xs:'column', sm: 'row'}} width={'fit-content'}>
            <Button
              sx={{
                backgroundColor: "white",
                borderRadius: "50px",
                color: "#262626",
                fontFamily: "Nunito",
                padding: '12px 24px',
                marginBottom: { xs: '20px', md: '0' },
                height: 'auto'
              }}
            >
              <Icon icon="logos:apple" style={{ fontSize: '30px', marginRight: '10px' }} />
              <Stack sx={{ lineHeight: '16px', textAlign: 'left' }}>
                <Typography variant="p3" fontWeight={'500'}>Download on the</Typography>
                <Typography variant="p7" fontWeight={'700'}>App Store</Typography>
              </Stack>
            </Button>
            <Button
              sx={{
                backgroundColor: "white",
                borderRadius: "50px",
                color: "#262626",
                fontFamily: "Nunito",
                padding: '12px 24px',
                height: 'auto'
              }}
            >
              <Icon icon="logos:google-play-icon" style={{ fontSize: '30px', marginRight: '10px' }} />
              <Stack sx={{ lineHeight: '16px', textAlign: 'left' }}>
                <Typography variant="p3" fontWeight={'500'}>Get it on the</Typography>
                <Typography variant="p7" fontWeight={'700'}>Google Play</Typography>
              </Stack>
            </Button>
          </Grid>
          <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'start', md: 'end' }
            }}
          >
            <Box>
              <Typography
                color={"white"}
                sx={{ marginTop: { xs: '40px', md: '0' } }}
              >
                © 2024 Get A Bid. All Rights Reserved.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
