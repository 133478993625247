import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';
import theme from './config/theme';
import Routes from './routes';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './context/AuthContext';
import { LoaderProvider } from './context/LoaderContext';
import Loader from './components/Loader';
import ScrollToTop from './components/Scroller';

const App = () => {

  return (
    <LoaderProvider>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Toaster />
            <Loader />
            <ScrollToTop />
            <Routes />
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </LoaderProvider>
  )
}

export default App

