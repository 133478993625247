import React from "react";
import { useTimer } from "../../../context/TimerContext";

export const AuctionTimer = () => {
    const { timeRemaining, formatTime, loading  } = useTimer();

    if (loading) return "Loading...";
    return timeRemaining > 0
        ? `${formatTime(timeRemaining)} remaining`
        : "Auction ended";
};
