import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const fetchUserNotification = async (page, pageSize, markAsRead) => {
    try {
        // const response = await axiosInstance.get(API_ENDPOINTS.ADMIN.GET_ALL_LISTINGS);
        const endpoint = API_ENDPOINTS.NOTIFICATION.USER_NOTIFICATION;
        const response = await axiosInstance.get(endpoint, {
            params: {
                page,
                pageSize,
                markAsRead: false
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const markUserNotificationasRead = async (page, pageSize, markAsRead) => {
    try {
        // const response = await axiosInstance.get(API_ENDPOINTS.ADMIN.GET_ALL_LISTINGS);
        const endpoint = API_ENDPOINTS.NOTIFICATION.USER_NOTIFICATION;
        const response = await axiosInstance.get(endpoint, {
            params: {
                page,
                pageSize,
                markAsRead,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};