import React from 'react';
import { Menu, MenuItem, Box, Avatar } from "@mui/material";

const UserMenu = ({ anchorElUser, handleMenuClose, handleMenuItemClick, menuItems, selectedSection, name, email, avatar, logout }) => (
    <Menu
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
        <Box sx={{ padding: "10px", display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
            <Avatar src={avatar} sx={{ width: 40, height: 40, bgcolor: "lightgray" }} />
            <Box>
                <strong style={{ display: "block" }}>{name}</strong>
                <span style={{ fontSize: "12px", color: "gray" }}>{email}</span>
            </Box>
        </Box>
        {menuItems.map((item) => (
            <MenuItem
                key={item.section}
                onClick={() => handleMenuItemClick(item.section)}
                sx={{ color: selectedSection === item.section ? '#22B14B' : "#262626" }}
            >
                {item.label}
            </MenuItem>
        ))}
        <MenuItem onClick={logout} sx={{ color: '#262626' }}>Logout</MenuItem>
    </Menu>
);

export default UserMenu