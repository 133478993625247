import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const getUser = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.USER.GET_PROFILE);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (userData) => {
  try {
    const response = await axiosInstance.put(API_ENDPOINTS.USER.UPDATE_PROFILE,userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadUserImage = async (formData) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.USER.UPLOAD_PROFILE_IMAGE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};