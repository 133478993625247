import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import UserProvider from './context/UserContext';
import './styles/global.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
