import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const login = async (credentials) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.USER.LOGIN, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (userData) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.USER.REGISTER, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.USER.FORGOT_PASSWORD, email);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.USER.RESET_PASSWORD, token, newPassword);
    return response.data;
  } catch (error) {
    throw error;
  }
};
