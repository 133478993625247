import React from 'react';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";
import { Icon } from '@iconify/react';


const VehicleDetailsSection = ({ values, handleChange, errors, setFieldValue, makeOptions, modelOptions, transmissionOptions, engineTypeOptions, bodyTypeOptions, modelYearOptions, Options, fetchModel, disabled = false }) =>
(
    <>
        <Typography variant="h4" mb={2} mt={2}>
            Vehicle Details
        </Typography>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Make*</Typography>
                <SelectInput
                    name="make"
                    label="Please Select"
                    value={values.make}
                    // setFieldValue={(field, selectedMake) => {
                    //   setFieldValue("make", selectedMake);
                    //   setFieldValue("model", "");
                    //   fetchModel(selectedMake.value);
                    // }}
                    onChange={(event) => {
                        const selectedMake = event.target.value;
                        setFieldValue("make", selectedMake);
                        fetchModel(selectedMake);
                    }}
                    options={makeOptions}
                    error={!!errors.make && !values.make}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Model*</Typography>
                    <SelectInput
                        name="model"
                        label="Please Select"
                        value={values.model}
                        onChange={handleChange}
                        // setFieldValue={setFieldValue}
                        disabled={disabled || !values.make}
                        options={modelOptions}
                        error={!!errors.model && !values.model}
                    />
                </Stack>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Model Year*</Typography>
                    <SelectInput
                        name="modelYear"
                        label="Please Select"
                        value={values.modelYear}
                        onChange={handleChange}
                        // setFieldValue={setFieldValue}
                        options={modelYearOptions}
                        error={!!errors.modelYear && !values.modelYear}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Transmission*</Typography>
                <SelectInput
                    name="transmission"
                    label="Please Select"
                    value={values.transmission}
                    onChange={handleChange}
                    // setFieldValue={setFieldValue}
                    options={transmissionOptions}
                    error={!!errors.transmission && !values.transmission}
                    disabled={disabled}
                />
            </Stack>
        </Grid>


        <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Engine Type*</Typography>
                    <SelectInput
                        name="engineType"
                        label="Please Select"
                        value={values.engineType}
                        onChange={handleChange}
                        // setFieldValue={setFieldValue}
                        options={engineTypeOptions}
                        error={!!errors.engineType && !values.engineType}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Body Type*</Typography>
                    <SelectInput
                        name="bodyType"
                        label="Please Select"
                        value={values.bodyType}
                        onChange={handleChange}
                        // setFieldValue={setFieldValue}
                        options={bodyTypeOptions}
                        // error={false}
                        error={!!errors.bodyType && !values.bodyType}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Mileage*</Typography>
                    <TextInput
                        label="Please Enter"
                        name="mileage"
                        value={values.mileage}
                        onChange={handleChange}
                        fullWidth
                        // error={!!errors.mileage && !values.mileage}
                        error={Boolean(errors.mileage)}
                        helperText={errors.mileage &&
                            (errors.mileage !== "Mileage is required" ? errors.mileage : '')
                        }
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Location*</Typography>
                    <TextInput
                        label="Please Enter"
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                        fullWidth
                        error={!!errors.location && !values.location}
                        disabled={disabled}
                    />
                </Stack>
            </Grid>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Engine Capacity* (cc)</Typography>
                <TextInput
                    label="Please Enter"
                    name="engineCapacity"
                    value={values.engineCapacity}
                    onChange={handleChange}
                    fullWidth
                    // error={!!errors.engineCapacity && !values.engineCapacity}
                    error={Boolean(errors.engineCapacity)}
                    helperText={errors.engineCapacity &&
                        (errors.engineCapacity !== "Engine capacity is required" ? errors.engineCapacity : '')
                    }
                    disabled={disabled}
                // onBlur={handleBlur}
                />
            </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Seat Capacity*</Typography>
                <SelectInput
                    name="seatCapacity"
                    label="Please Select"
                    value={values.seatCapacity}
                    onChange={handleChange}
                    // setFieldValue={setFieldValue}
                    options={Options?.seatCapacityOptions}
                    error={!!errors.seatCapacity && !values.seatCapacity}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Exterior Color*</Typography>
                    <TextInput
                        label="Please Enter"
                        name="exteriorColor"
                        value={values.exteriorColor}
                        onChange={handleChange}
                        fullWidth
                        error={!!errors.exteriorColor}
                        helperText={errors.exteriorColor &&
                            (errors.exteriorColor !== "Exterior Color is required" ? errors.exteriorColor : '')
                        }
                        disabled={disabled}
                    />
                </Stack>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="p1">Interior Color*</Typography>
                    <TextInput
                        label="Please Enter"
                        name="interiorColor"
                        value={values.interiorColor}
                        onChange={handleChange}
                        fullWidth
                        error={!!errors.interiorColor}
                        helperText={errors.interiorColor &&
                            (errors.interiorColor !== "Interior Color is required" ? errors.interiorColor : '')
                        }
                        disabled={disabled}
                    />
                </Stack>
            </Grid>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
                <Typography variant="p1">Loan or Lease Status*</Typography>
                <RadioInput
                    name="loanOrLeaseStatus"
                    // label="Loan or Lease Status"
                    value={values.loanOrLeaseStatus}
                    options={Options?.loanLeaseOptions}
                    onChange={handleChange}
                    error={!!errors.loanOrLeaseStatus && !values.loanOrLeaseStatus}
                    helperText={errors?.loanOrLeaseStatus}
                    disabled={disabled}
                />
            </Stack>
        </Grid>

        {
            values.loanOrLeaseStatus === 'loan' && (
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Stack spacing={1} sx={{ width: "100%" }}>
                            <Typography variant="p1">Company Name*</Typography>
                            <TextInput
                                label="Please Enter Company Name"
                                name="companyName"
                                value={values.companyName}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.companyName && !values.companyName}
                                disabled={disabled}
                            />
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Stack spacing={1} sx={{ width: "100%" }}>
                            <Typography variant="p1">Remaining Balance*</Typography>
                            <TextInput
                                type="number"
                                name="dueAmount"
                                label="Please Enter Remaining Balance"
                                value={values.dueAmount}
                                onChange={handleChange}
                                fullWidth
                                // error={!!errors.dueAmount && !values.dueAmount}
                                error={Boolean(errors.dueAmount)}
                                helperText={errors.dueAmount &&
                                    (errors.dueAmount !== "Remaining balance or payoff amount is required" ? errors.dueAmount : '')
                                }
                                disabled={disabled}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            )
        }

        {
            values.loanOrLeaseStatus === 'lease' && (
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Stack spacing={1} sx={{ width: "100%" }}>
                            <Typography variant="p1">Company Name*</Typography>
                            <TextInput
                                label="Please Enter Company Name"
                                name="companyName"
                                value={values.companyName}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.companyName && !values.companyName}
                                disabled={disabled}
                            />
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Stack spacing={1} sx={{ width: "100%" }}>
                            <Typography variant="p1">Payoff Amount*</Typography>
                            <TextInput
                                type="number"
                                label="Please Enter Lease Payoff Amount"
                                name="dueAmount"
                                value={values.dueAmount}
                                onChange={handleChange}
                                fullWidth
                                // error={!!errors.dueAmount && !values.dueAmount}
                                error={Boolean(errors.dueAmount)}
                                helperText={errors.dueAmount &&
                                    (errors.dueAmount !== "Remaining balance or payoff amount is required" ? errors.dueAmount : '')
                                }
                                disabled={disabled}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            )
        }
    </>
)

export default VehicleDetailsSection