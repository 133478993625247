export const NOTIFICATION_TYPES = {
    // Admin Notifications
    ADMIN: {
        ACTIVATE_ACCOUNT: 'admin_activate_account',
        DEACTIVATE_ACCOUNT: 'admin_deactivate_account',
        ACCEPT_AD: 'admin_accept_ad',
        REJECT_AD: 'admin_reject_ad',
        ACCEPT_INSPECTION: 'admin_accept_inspection',
        REJECT_INSPECTION: 'admin_reject_inspection',
        PENDING_AD_APPROVAL: 'admin_pending_ad_approval',
        PENDING_INSPECTION_APPROVAL: 'admin_pending_inspection_approval',
    },

    // Consumer Notifications
    CONSUMER: {
        AD_PENDING: 'consumer_ad_pending',
        AD_APPROVED: 'consumer_ad_approved',
        INSPECTION_APPROVED: 'consumer_inspection_approved',
        AD_REJECTED: 'consumer_ad_rejected',
        INSPECTION_REJECTED: 'consumer_inspection_rejected',
        AD_LIVE: 'consumer_ad_live',
        AUCTION_ENDED_VIEW_BIDS: 'consumer_auction_ended_view_bids',
        AUCTION_ENDED_HIGHEST_BID_ACCEPTED: 'consumer_auction_ended_highest_bid_accepted',
        DEALER_COUNTER_OFFER: 'consumer_dealer_counter_offer',
        DEALER_REJECTED_OFFER: 'dealer_rejected_offer',
        DEALER_ACCEPTED_OFFER: 'consumer_dealer_accepted_offer',
        AD_SOLD: 'consumer_ad_sold',
        AD_SOLD_BY_ACCEPTING_BID: 'consumer_ad_sold_by_accepting_bid',
        DEALER_NEGOTIATION_OFFER: 'consumer_dealer_negotiation_offer',
        OFFER_ACCEPTED: 'consumer_offer_accepted',
        PENDING_INSPECTION: 'consumer_pending_inspection',
    },

    // Dealer Notifications
    DEALER: {
        NEW_AD_AUCTION: 'dealer_new_ad_auction',
        BID_OUTBID: 'dealer_bid_outbid',
        BID_ACCEPTED_AUCTION_WON: 'dealer_bid_accepted_auction_won',
        CONSUMER_NEGOTIATION_OFFER: 'dealer_consumer_negotiation_offer',
        COUNTER_OFFER_ACCEPTED: 'dealer_counter_offer_accepted',
        OFFER_ACCEPTED: 'dealer_offer_accepted',
    },
}

