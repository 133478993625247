import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const createAd = async (formData) => {
    try {
        const response = await axiosInstance.post(API_ENDPOINTS.CONSUMER.CREATE_LISTING, formData,
            // {timeout:30000},  
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        return response.data;
    } catch (error) {
        console.log("error", error)
        throw error;
    }
};


export const fetchAd = async () => {
    try {
        const response = await axiosInstance.get(API_ENDPOINTS.CONSUMER.GET_LISTINGS);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const viewAd = async (Id) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.GET_LISTING_BY_ID.replace(':listingId', Id);
        const response = await axiosInstance.get(endpoint);
        // const response = await axiosInstance.get(API_ENDPOINTS.LISTING.);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateAd = async (listingId, formData) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.UPDATE_LISTING.replace(':listingId', listingId);
        const response = await axiosInstance.put(endpoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        // const response = await axiosInstance.get(API_ENDPOINTS.LISTING.);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const consumerAd = async (Id) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.GET_LISTING_DETAILS.replace(':listingId', Id);
        const response = await axiosInstance.get(endpoint);
        // const response = await axiosInstance.get(API_ENDPOINTS.LISTING.);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const goLive = async (listingId) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.MAKE_LISTING_LIVE.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateReservedPrice = async (listingId, reservedPrice) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.UPDATE_RESERVED_PRICE.replace(':listingId', listingId);
        const response = await axiosInstance.patch(endpoint, { reservedPrice });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const repostAd = async (listingId) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.REPOST_LISTING.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchVinNo = async (vin) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.DECODE_VIN;
        const response = await axiosInstance.get(endpoint, {
            params: {
                vin,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const acceptBid = async (listingId, bidId) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.ACCEPT_BID
            .replace(':listingId', listingId)
            .replace(':bidId', bidId);
        const response = await axiosInstance.post(endpoint);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const submitInspection = async (listingId, inspectionData) => {
    try {
        const endpoint = API_ENDPOINTS.CONSUMER.INSPECTION.replace(':listingId', listingId);
        const response = await axiosInstance.post(endpoint, inspectionData);
        console.log('responseseses', response)
        return response.data;
    } catch (error) {
        throw error;
    }
};


